import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useDeleteCommandeMutation } from "../../redux/features/commandeFeature";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../utils/DeleteModal"
import { COMMANDES_PATH } from "../../routes/path/navigation_path";

const DeleteCommande = ({ showModal, setShowModal, onDeleted }) => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state?.UserSlice?.info);
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [deleteCommande] = useDeleteCommandeMutation();

  function closeModal() {
    setShowModal(false);
  }

  const handleDeleteCommande = () => {
    setIsLoading(true);
  
    deleteCommande(id)
      .unwrap()
      .then((data) => {
        if (data) {
          // Suppression réussie
          closeModal();
          toast.success("Commande supprimée");
          onDeleted(); // Appeler cette fonction pour indiquer que la commande a été supprimée
          setTimeout(() => {
            navigate(COMMANDES_PATH);
          }, 1000);
        } else {
          // data est null, mais la suppression a réussi
          closeModal();
          onDeleted();
          navigate(COMMANDES_PATH);
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("Une erreur s'est produite lors de la suppression de la commande");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  

  return (
    <DeleteModal
      showModal={showModal}
      setShowModal={setShowModal}
      onClose={closeModal}
      title="Suppression commande"
      titleIconClass="white-users-icon"
    >
      <ToastContainer />
      <div className="p-2">
        <p className="text-center py-3">
          Voulez vous vraiment supprimer cette commande?
        </p>
        <div className="text-center" style={{display:'flex', justifyContent:'space-between', flexDirection:'row', margin:'auto'}}>
          <button
            type="submit"
            className=" btn btn-secondary text-white py-2 px-4 mx-3 mt-3"
            onClick={closeModal}
          >
            Annuler
          </button>
          <button
          className="btn text-white py-2 px-4 mx-3 mt-3 text-align-center"
            style={{
              backgroundColor: "#E82828",
              color: "#FFFFFF",
            }}
            disabled={isLoading}
            onClick={handleDeleteCommande}
            type="submit"
          >
            {isLoading ? (
              <div className="spinner-border text-light" role="status"></div>
            ) : (
              "Supprimer"
            )}
          </button>
        </div>
      </div>
    </DeleteModal>
  );
};

export default DeleteCommande;
