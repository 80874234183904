import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaArrowCircleLeft, FaTrashAlt } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";
import "./css/addClient.css";
import { isValidMobilePhone, isValidText } from "../../utils/form_control";
import Perso from "../../assets/personaliser.svg";
import ChevronLeft from "../../assets/chevron-left.svg";
import {
  useAddClientMutation,
  useGetClientsQuery,
} from "../../redux/features/clientFeature";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  CLIENTS_PATH,
  PERSONALISER_PATH,
} from "../../routes/path/navigation_path";
import { IoIosArrowBack } from "react-icons/io";
import Layout from "../../component/templateMobile/TemplateMobile";
import { addNewClient } from "../../redux/slices/clientSlice";
import SelectInput from "../../utils/Material-ui/InputSelect";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../component/Spinner";
import { useTranslation } from "react-i18next";

const AddClient = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
    control,
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useSelector((state) => state.UserSlice?.info);
  const navigate = useNavigate();
  const { refetch } = useGetClientsQuery(currentUser?.id);
  const dispatch = useDispatch();
  const [showController, setShowController] = useState(false);
  const [addClient] = useAddClientMutation();
  const [custumiseForm, setIsCustmiseForm] = useState(false);
  const [isFemale, setIsFemale] = useState(true);
  const [validatePays, setValidatePay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customFields, setCustomFields] = useState([
    { nomMesure: "" },
    { uniteMesure: "" },
  ]);
  const { t } = useTranslation();
  const toggalForm = () => {
    setIsCustmiseForm((prevCustomisble) => !prevCustomisble);
  };
  // ddddddddddddddddddddddddddddddddddddddddddddddddddddd
  // const [showButton, setShowButton] = useState(true);

  const addCustomField = () => {
    if (customFields.length === 0) {
      setCustomFields([{ nomMesure: "", uniteMesure: "" }]);
    } else {
      const newField = { nomMesure: "", uniteMesure: "" };
      setCustomFields([...customFields, newField]);
    }
    setShowController(true);
  };
  // setShowButton(false);
  // const hasUnitOfMeasure = customFields.some(field => field.mesure_client !== "");
  // ccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccc
  const removeCustomField = (index) => {
    const updatedFields = [...customFields];
    updatedFields.splice(index, 1);
    setCustomFields(updatedFields);
  };
  const handlePays = (e) => {
    const inputValue = e.target.value + e.key;
    if (e.key !== "Backspace" && !/^[a-zA-ZÀ-ÿ\s]*$/.test(inputValue)) {
      e.preventDefault();
      setValidatePay(true);
    } else {
      setValidatePay(false);
    }
  };
  const { data: clientsData, refetch: refeching } = useGetClientsQuery(currentUser?.id); 
  const handleAddClient = async (data) => {
    const { nom_complet, telephone } = data;

    // Vérifier si le client existe déjà
    const clientExists = clientsData?.find(client =>
      client.nom_complet.toLowerCase() === nom_complet.toLowerCase() &&
      client.telephone === telephone
    );

    if (clientExists) {
      toast.error("Ce client existe déjà !");
      return;
    }

    setLoading(true);

    setTimeout(async () => {
      try {
        const response = await addClient({
          nom_complet,
          telephone,
          createdBy: currentUser?.id,
        });

        setLoading(false);
        refeching();
        dispatch(addNewClient(data));
        toast.success("Ajout client réussi !");
        setTimeout(() => {
          navigate(CLIENTS_PATH);
        }, 1000);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }, 2000);
  };
  

  const verificate = (data) => {
    let errorFields = [];

    if (!isValidText(data?.nom_complet)) {
      errorFields.push({
        field: "nom_complet",
        message: "Veuillez un saisir un nom valide!",
      });
    }
    if (!isValidMobilePhone(data?.telephone)) {
      errorFields.push({
        field: "telephone",
        message: "Veuillez saisir un numéro valide!",
      });
    }

    if (errorFields?.length) {
      errorFields?.map((err) => {
        let { field, message } = err;
        setError(field, { message });
        return err;
      });
    } else {
      handleAddClient(data);
      refetch();
      dispatch(addNewClient(data));
      toast.success("Ajout client succès !"); // Utilisez toast pour l'alerte de succès
      setTimeout(() => {
        navigate(CLIENTS_PATH);
      }, 1000);
    }
  };
  const leftContent = <IoIosArrowBack size={24} onClick={() => navigate(-1)} />;

  const centerContent = (
    <div className="titre-header">
      <span>{t("Enregistrement Client")}</span>
    </div>
  );
  const rightContent = null;

  return (
    <Layout
      leftContent={leftContent}
      centerContent={centerContent}
      rightContent={rightContent}
    >
      <div className="container">
        <ToastContainer />
        <form
          onSubmit={handleSubmit(verificate)}
          className="add-client row my-4 pb-4 fs-90 text-start bg-white"
        >
          <div className="col-lg space-client mt-3">
            <div className="bg-white mx-4 ms-md-3 rounded">
              <div className="">
                <div className="">
                  <p className="text-form">{t("Nom complet")}</p>
                  <input
                    {...register("nom_complet", {
                      required: "Champ obligatoire",
                    })}
                    type="text"
                    className="form-control"
                    style={{ height: "40px" }}
                    error={errors?.nom_complet}
                  />

                  {errors?.nom_complet && (
                    <p style={{ color: "red" }}>
                      {errors.nom_complet?.message}
                    </p>
                  )}
                </div>
                <div className="">
                  <p className="text-form">{t("Téléphone")}</p>
                  <input
                    {...register("telephone", {
                      required: "Champ obligatoire",
                      maxLength: {
                        value: 9,
                        message:
                          "Le numéro de téléphone  doit etre de 9 chiffres.",
                      },
                    })}
                    type="number"
                    className={`form-control ${
                      errors.telephone ? "is-invalid" : ""
                    }`}
                    style={{ height: "40px" }}
                  />

                  {errors.telephone && (
                    <p style={{ color: "red" }}>{errors.telephone?.message}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-12 mb-4 space-mesure">
            <div className="container">
              <button
                className="validateButton mt-2"
                type="submit"
                style={{ width: "91%", marginLeft: "16px" }}
                disabled={loading}
              >
                {loading ? (
                  <div
                    className="spinner-border text-white"
                    role="status"
                  ></div>
                ) : (
                  t("Valider")
                )}
              </button>
            </div>
          </div>
        </form>
        {/* </div> */}
      </div>
      <br></br>
    </Layout>
  );
};

export default AddClient;
