import React, { useEffect, useState } from "react";
import Header from "../../component/templateMobile/Header";
import "./css/detailCommande.css";
import { IoIosArrowBack } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import { LuPenLine } from "react-icons/lu";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link, useParams } from "react-router-dom";
import { BsCheck2Circle, BsImageFill } from "react-icons/bs";
import {
  useGetCommandeQuery,
  useGetCommandesByUserQuery,
} from "../../redux/features/commandeFeature";
import {
  COMMANDES_PATH,
  UPDATE_COMMANDE_PATH,
} from "../../routes/path/navigation_path";
import moment from "moment/moment";
import DeleteCommande from "./DeleteCommande";
import { DEFAULT_API_IMAGE } from "../../routes/api_routes";
import "../../utils/GlobalStyle.css";
import EditStatutCommande from "./statutCommande/EditStatutCommande";
import Layout from "../../component/templateMobile/TemplateMobile";
import SkeletonComponent from "../../utils/Material-ui/skeleton";
import CustomizedButtons from "../../utils/Material-ui/buttonCom";
import HistoryIcon from "@mui/icons-material/History";
import ModalPaiement from "./historiquePaiement/ModalPaiement";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import Modal from "../../component/ModalFacture";
import logo from "../../../src/assets/img/tayeur-logo1.png";
import imprimer from "../../assets/img/file-text.png";
import { toast } from "react-toastify";
import Historique from "./historiquePaiement/Historique";
import { useTranslation } from "react-i18next";
import ClientInfoComponent from "../../component/ClientInformationcomponent";
import { useGetCommandeClientByIdQuery } from "../../redux/features/commandeMesureFeature";

const DetailCommande = () => {
  const currentUser = useSelector((state) => state?.UserSlice?.info);
  const { id } = useParams();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [commandeIdToDelete, setCommandeIdToDelete] = useState(null);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [reloadSection, setReloadSection] = useState(false);
  const { t } = useTranslation();
  const { data: commande, isLoading } = useGetCommandeClientByIdQuery(id);
  const [commandeStatut, setCommandeStatut] = useState(commande?.statut);
  const [commandeId, setCommandeId] = useState(null);
  const [paie, setPaie] = useState(null);

  const handleDeleteCommande = (id) => {
    setCommandeIdToDelete(id);
    setShowDeleteModal(true);
  };

  const handleStatutChange = (nouveauStatut) => {
    setCommandeStatut(nouveauStatut);
  };

  const handleUpdateCommande = ({ nouveauMontant }) => {
    if (commande?.statut === "livree") {
      toast.warning("Modification not allowed for delivered commands.");
    } else {
      navigate(`/update-commande/${id}`);
    }
  };

  const leftContent = (
    <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
      <IoIosArrowBack size={24} />
    </div>
  );

  const centerContent = (
    <div className="titre-header">
      <span>{t("Detail Commande")}</span>
    </div>
  );

  const rightContent = (
    <div className="d-flex" style={{ color: "#E82828" }}>
      <RiDeleteBin6Line
        style={{ marginRight: "10px" }}
        onClick={() => handleDeleteCommande(id)}
      />
      <LuPenLine onClick={handleUpdateCommande} />
    </div>
  );

  const [showLoader, setShowLoader] = useState(true);
  useEffect(() => {
    const loaderTimeout = setTimeout(() => {
      setShowLoader(false);
    }, 200);

    return () => {
      clearTimeout(loaderTimeout);
    };
  }, []);

  const [showPaymentMessage, setShowPaymentMessage] = useState(false);
  const handleShowModal = () => {
    if (commande?.montant_restant === 0) {
      setShowPaymentMessage(true);
    }
  };

  useEffect(() => {
    setShowPaymentMessage(commande?.montant_restant === 0);
  }, [commande?.montant_restant, commande?.montant_paye]);

  const isCommandePayee = () => {
    return commande?.montant_restant === 0;
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalHide = () => {
    setIsModalOpen(false);
    setShowLoader(true);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handlePaymentModalHide = () => {
    setIsPaymentModalOpen(false);
    setShowLoader(false);
  };

  const openPaymentModal = () => {
    setIsPaymentModalOpen(true);
  };

  useEffect(() => {
    const loaderTimeout = setTimeout(() => {
      setShowLoader(false);
      setReloadSection(false);
    }, 1000);

    return () => {
      clearTimeout(loaderTimeout);
    };
  }, [reloadSection]);

  const mesuresDuModel = commande?.mesures || [];

  return (
    <Layout
      leftContent={leftContent}
      centerContent={centerContent}
      rightContent={rightContent}
    >
      {showLoader || reloadSection ? (
        <SkeletonComponent />
      ) : (
        <div style={{ background: "white", borderRadius: "4px" }}>
          <div style={{ padding: "20px" }}>
            <div className="detail_commande">
              <h6
                style={{
                  color: "#000000",
                  fontSize: "18px",
                  paddingLeft: "12px",
                }}
              >
                Détail commande
              </h6>
            </div>
            <hr style={{ color: "#DADADA" }} />
            <ClientInfoComponent
              text={t("Nom Complet")}
              Texvalue={commande?.client_nom}
            />
            <ClientInfoComponent
              text={t("Telephone")}
              Texvalue={commande?.client_phone}
            />
            <ClientInfoComponent
              text={t("Nom du modele")}
              Texvalue={commande?.model_nom}
            />
            <hr />
            <div className="row m-1 model">
              <h6 style={{ color: "#000000", fontSize: "18px" }}>
                {t("Mesures du modèle")}
              </h6>
              {mesuresDuModel.length > 0 ? (
                <ul>
                  {mesuresDuModel.map((mesure, index) => (
                    <li
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <label>{mesure.mesure_nom}</label>
                      <span>{mesure.valeur}</span>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>Aucune mesure disponible pour ce modèle.</p>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "20px",
              }}
            >
              {/* <EditStatutCommande
                onStatutChange={handleStatutChange}
                id={id}
                commande={commande}
              /> */}
            </div>
            {showDeleteModal && (
              <DeleteCommande
                showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                commandeIdToDelete={commandeIdToDelete}
                onDeleted={() => {}}
              />
            )}
            {commande && (
              <div>
                <div className="section1"></div>
              </div>
            )}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default DetailCommande;
