import React, { useState } from "react";
import {
  CLIENTS_PATH,
  DETAIL_CLIENT_PATH,
} from "../../routes/path/navigation_path";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetClientByIdQuery,
  useUpdateClientMutation,
} from "../../redux/features/clientFeature";
import { useParams, useNavigate } from "react-router-dom";
import CustomInput from "../../component/Input";
// import Header from "../../component/Header";
import { IoIosArrowBack } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import MesureComponent from "../../component/MesureComponent";
import { current } from "@reduxjs/toolkit";
import Layout from "../../component/templateMobile/TemplateMobile";
import { FiPlus } from "react-icons/fi";
import "./css/modifClient.css";
import SelectInput from "../../utils/Material-ui/InputSelect";
// import MesureComponent from "../../component/MesureComponent";

const UpdateClient = () => {
  const { id } = useParams();
  //   console.log(id);
  const { data: currentClient } = useGetClientByIdQuery(id);
  const [updateClient] = useUpdateClientMutation(id);
  const dispatch = useDispatch();

  console.log("donner client", currentClient);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    control,
  } = useForm({
    values: {
      nom_complet: currentClient?.nom_complet,
      telephone: currentClient?.telephone,
      adresse: currentClient?.adresse,
      pays: currentClient?.pays,
      Ville: currentClient?.Ville,
      cou: currentClient?.cou,
      epaule: currentClient?.epaule,
      longueur_manche: currentClient?.longueur_manche,
      ceinture: currentClient?.ceinture,
      tour_bras: currentClient?.tour_bras,
      hanche: currentClient?.hanche,
      longueur_jupe: currentClient?.longueur_jupe,
      longueur_robe: currentClient?.longueur_robe,
      longueur_poitrine: currentClient?.longueur_poitrine,
      taille: currentClient?.taille,
      cuisse: currentClient?.cuisse,
      longueur_haut: currentClient?.longueur_haut,
      uniteMesure: currentClient?.uniteMesure,
      nomMesure: currentClient?.nomMesure,
    },
    defaultValues: {
      nom_complet: currentClient?.nom_complet,
      telephone: currentClient?.telephone,
      adresse: currentClient?.adresse,
      pays: currentClient?.pays,
      Ville: currentClient?.Ville,
      cou: currentClient?.cou,
      epaule: currentClient?.epaule,
      longueur_manche: currentClient?.longueur_manche,
      ceinture: currentClient?.ceinture,
      tour_bras: currentClient?.tour_bras,
      hanche: currentClient?.hanche,
      longueur_jupe: currentClient?.longueur_jupe,
      longueur_robe: currentClient?.longueur_robe,
      longueur_poitrine: currentClient?.longueur_poitrine,
      taille: currentClient?.taille,
      cuisse: currentClient?.cuisse,
      longueur_haut: currentClient?.longueur_haut,
      uniteMesure: currentClient?.uniteMesure,
      nomMesure: currentClient?.nomMesure,
    },
  });
  const currentUser = useSelector((state) => state.UserSlice?.info);
  // Remplacez par la mutation d'actualisation appropriée depuis votre API
  const navigate = useNavigate();

  const leftContent = (
    <div style={{ cursor: "pointer", color: "black" }}>
      <IoIosArrowBack size={24} onClick={() => navigate(-1)} />
    </div>
  );
  const centerContent = (
    <div className="titre-header">
      <span>Modifier le Client</span>
    </div>
  );
  const rightContent = null;

  const onSubmit = async (data) => {
    setLoading(true);
    // Eclgffectuez ici la logique d'actualisation du client avec les données de "data"
    try {
      // Utilisez la mutation d'actualisation pour mettre à jour le client
      const response = await updateClient({
        id: currentClient.id,
        body: data,
      });
      // Assurez-vous d'ajuster les paramètres de la mutation d'actualisation
      console.log("Client mis à jour :", response);
      toast.success("Client modifié avec succès !"); // Utilisez toast pour l'alerte de succès
      setTimeout(() => {
        setLoading(false); // Définissez l'état de chargement sur false après la fin de la requête
        navigate(-1);
      }, 1000);
      // Redirigez l'utilisateur vers la page de détails du client ou une autre page appropriée
    } catch (error) {
      console.error("Erreur lors de la mise à jour du client :", error);
      // Gérez les erreurs d'actualisation ici
      setLoading(false);
    }
  };
  const [mesures, setMesures] = useState(currentClient?.mesure_client || []); // Assurez-vous que currentClient est défini

  const ajouterChampMesure = () => {
    setMesures([...mesures, { nomMesure: "", uniteMesure: "" }]);
  };

  const supprimerChampMesure = (index) => {
    const nouvellesMesures = [...mesures];
    nouvellesMesures.splice(index, 1);
    setMesures(nouvellesMesures);
  };

  return (
    <Layout
      leftContent={leftContent}
      centerContent={centerContent}
      rightContent={rightContent}
    >
      <div className=" border  p-3 shadow ">
        <ToastContainer />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            {" "}
            <label className="form-label ">Nom complet</label>
          </div>

          <CustomInput
            type="text"
            name="nom_complet"
            id="nom_complet"
            className="form-control mb-2"
            register={{
              ...register("nom_complet", {
                required: "champs obligatoire",
              }),
            }}
            defaultValue={currentClient?.nom_complet}
            error={errors.nom_complet}
          />
          {errors.nom_complet && (
            <p style={{ color: "red" }}>{errors.nom_complet.message}</p>
          )}

          <div>
            {" "}
            <label className="form-label ">Téléphone</label>
          </div>
          <CustomInput
            type="number"
            name="telephone"
            id="telephone"
            className="form-control"
            register={{
              ...register("telephone", {
                required: "Champs obligatoire",
                pattern: {
                  value: /^[0-9]{9}$/, // Expression régulière pour 9 chiffres exacts
                  message:
                    "Le numéro de téléphone doit comporter exactement 9 chiffres.",
                },
              }),
            }}
            defaultValue={currentClient?.telephone}
            error={errors.telephone}
          />
          {errors.telephone && (
            <p style={{ color: "red" }}>{errors.telephone.message}</p>
          )}

          <div>
           
          </div>
      
          <div>
          </div>

        </form>
      </div>
      <br></br>
      <br></br>
      <br></br>
    </Layout>
  );
};

export default UpdateClient;
