import { PARAMETRE_API } from "../../routes/api_routes";
import { ApiMananger } from "../apiManager";

const parametreApi = ApiMananger.injectEndpoints({overrideExisting : true,
  endpoints: (build) => ({
    getParametre: build.query({
      query: (id) => PARAMETRE_API.GET(id),
      providesTags: ["Parametre"],
    }),
    getParametreByUser: build.query({
      query: (id) => PARAMETRE_API.GETBYUSER(id),
      providesTags: ["Parametre"],
    }),
    addParametre: build.mutation({
      query({ body }) {
        return {
          url: PARAMETRE_API.ADD(),
          method: "POST",
          headers: {
            Accept: "application/json",
            // "Content-Type": "multipart/form-data",
          },
          body,
        };
      },
      invalidatesTags: ["Parametre"],
    }),
    updateParametre: build.mutation({
      query(data) {
        const { id, body } = data;
        // console.log(data)
        return {
          url: PARAMETRE_API.EDIT(id),
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Parametre"],
    }),
    deleteParametre: build.mutation({
      query(id) {
        return {
          url: PARAMETRE_API.DELETE(id),
          method: "DELETE",
        };
      },
      invalidatesTags: ["Parametre"],
    }),

  }),
});

export const {
    useGetParametreQuery,
    useGetParametreByUserQuery,
    useAddParametreMutation,
    useUpdateParametreMutation,
    useDeleteParametreMutation,
} = parametreApi;
