
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GETCOMMANDES_API_ROUTE } from '../../routes/api_routes';
import { ApiMananger } from '../apiManager';

export const commandeClientApi = ApiMananger.injectEndpoints ({
  baseQuery: fetchBaseQuery({ baseUrl: GETCOMMANDES_API_ROUTE }),
  tagTypes: ['CommandeClient'],
  keepUnusedDataFor: 30,
  endpoints: (builder) => ({
    getCommandeClient: builder.query({
      query: () => 'commandesV2/',
      providesTags: ['CommandeClient'],
    }),
    getCommandeClientById: builder.query({
      query: (id) => `commandeV2_detail/${id}`,
      keepUnusedDataFor: 5
    }),
  }),
});

export const { useGetCommandeClientQuery, useGetCommandeClientByIdQuery } = commandeClientApi;

