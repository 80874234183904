import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FiPlus } from "react-icons/fi";
import "../clients/css/addClient.css";
import { isValidMobilePhone, isValidText } from "../../utils/form_control";
import {
  useAddClientMutation,
  useGetClientsQuery,
} from "../../redux/features/clientFeature";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { addNewClient } from "../../redux/slices/clientSlice";
import SelectInput from "../../utils/Material-ui/InputSelect";
import { ToastContainer, toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const AddClientInCommandeHomme = ({ closeModal, showModal, data, setAddedClientId  }) => {
  const id = data?.id;

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
    control,
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useSelector((state) => state.UserSlice?.info);
  const navigate = useNavigate();
  const { refetch } = useGetClientsQuery(currentUser?.id);
  const dispatch = useDispatch();
  const [showController, setShowController] = useState(false);
  const [addClient] = useAddClientMutation();
  const [custumiseForm, setIsCustmiseForm] = useState(false);
  const [isFemale, setIsFemale] = useState(true);
  const [validatePays, setValidatePay] = useState(false);
  const { t } = useTranslation();
  const [customFields, setCustomFields] = useState([
    { nomMesure: "" },
    { uniteMesure: "" },
  ]);
  const toggalForm = () => {
    setIsCustmiseForm((prevCustomisble) => !prevCustomisble);
  };
  // ddddddddddddddddddddddddddddddddddddddddddddddddddddd
  const addCustomField = () => {
    if (customFields.length === 0) {
      // Si customFields est vide, initialisez-le avec un champ
      setCustomFields([{ nomMesure: "", uniteMesure: "" }]);
    } else {
      // Ajoutez un nouveau champ de mesure
      const newField = {
        nomMesure: "",
        uniteMesure: "",
      };
      setCustomFields([...customFields, newField]);
    }
    // Assurez-vous de définir showController sur true après avoir ajouté un champ
    setShowController(true);
  };
  // ccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccc
  const removeCustomField = (index) => {
    const updatedFields = [...customFields];
    updatedFields.splice(index, 1);
    setCustomFields(updatedFields);
  };
  const handlePays = (e) => {
    const inputValue = e.target.value + e.key;
    if (e.key !== "Backspace" && !/^[a-zA-ZÀ-ÿ\s]*$/.test(inputValue)) {
      e.preventDefault();
      setValidatePay(true);
    } else {
      setValidatePay(false);
    }
  };

  const handleAddClient = async (data) => {
    const newData = {
      nom_complet: data?.nom_complet,
      telephone: data?.telephone,
      adresse: data?.adresse,
      pays: data?.pays || null,
      Ville: data?.Ville || null,
      sexe: data?.sexe,
      cou: parseInt(data?.cou),
      epaule: parseInt(data?.epaule),
      longueur_boubou: parseInt(data?.longueur_boubou),
      longueur_haut: parseInt(data?.longueur_haut),
      longueur_manche: parseInt(data?.longueur_manche),
      longueur_bras: parseInt(data?.longueur_bras),
      cuisse: parseInt(data?.cuisse),
      longueur_pantalon: parseInt(data?.longueur_pantalon),
      hanche: parseInt(data.hanche),
      ceinture: parseInt(data?.ceinture),
      tour_bras: parseInt(data?.tour_bras),
      poitrine: parseInt(data?.poitrine),
      taille: parseInt(data?.taille),
      longueur_robe: parseInt(data?.longueur_robe),
      longueur_poitrine: parseInt(data?.longueur_poitrine),
      longueur_jupe: parseInt(data?.longueur_jupe),
      mesure_client: data?.mesure_client,
      createdBy: currentUser?.id,
    };
    // console.log(newData);
    const reponse = await addClient(newData);
  };

  const verificate = (data) => {
    let errorFields = [];

    if (!isValidText(data?.nom_complet)) {
      errorFields.push({
        field: "nom_complet",
        message: "Veuillez un saisir un nom valide!",
      });
    }
    if (!isValidMobilePhone(data?.telephone)) {
      errorFields.push({
        field: "telephone",
        message: "Veuillez saisir un numéro valide!",
      });
    }

    if (errorFields?.length) {
      errorFields?.map((err) => {
        let { field, message } = err;
        setError(field, { message });
        return err;
      });
    } else {
      handleAddClient(data);
      refetch();
      dispatch(addNewClient(data));
      toast.success("Ajout client succès !"); // Utilisez toast pour l'alerte de succès
      setAddedClientId(data?.id);
      setTimeout(() => {
        closeModal(true)
      }, 1000);

      setTimeout(() => {
        // Après un certain temps (simulation de la fin de la requête), mettez le chargement à faux
        setIsLoading(false);
      }, 2000);

      console.log("======>", errorFields);
    }
  };
  const leftContent = <IoIosArrowBack size={24} onClick={() => navigate(-1)} />;

  const centerContent = (
    <div className="titre-header">
      <span>Enregistrement client</span>
    </div>
  );
  const rightContent = null;

  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title style={{ color: "red", textAlign: "center" }}>
          {t("Ajout client")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={handleSubmit(verificate)}
          className="add-client row my-4 pb-4 fs-90 text-start "
        >
          <div className="col-lg space-client mt-3">
            <div className="bg-white mx-4 ms-md-3 rounded">
              {/* <div>
                <p className="title mb-4">INFORMATIONS CLIENT</p>
              </div> */}
              <div className="">
                <div className="">
                  <p className="text-form">{t("Nom complet")}</p>
                  <input
                    {...register("nom_complet", {
                      required: "Champ obligatoire",
                    })}
                    type="text"
                    className="form-control"
                    style={{ height: "40px" }}
                    error={errors?.nom_complet}
                  />

                  {errors?.nom_complet && (
                    <p style={{ color: "red" }}>
                      {errors.nom_complet?.message}
                    </p>
                  )}

                  {/* <p className="error-msg text-center">
                    {errors.nom_complet?.message}
                  </p> */}
                </div>
                <div className="">
                  <p className="text-form">{t("Téléphone")}</p>
                  <input
                    {...register("telephone", {
                      required: "Champ obligatoire",
                      maxLength: {
                        value: 9,
                        message:
                          "Le numéro de téléphone  doit etre de 9 chiffres.",
                      },
                    })}
                    type="number"
                    className={`form-control ${
                      errors.telephone ? "is-invalid" : ""
                    }`}
                    style={{ height: "40px" }}
                  />

                  {errors.telephone && (
                    <p style={{ color: "red" }}>{errors.telephone?.message}</p>
                  )}
                </div>
                <div className="mt-4 d-flex justify-content-between">
                  <span>{t("Genre")}</span>
                  <p className="mx-1 mx-lg-3">
                    <label>
                      <input
                        type="radio"
                        name="sexe"
                        onChange={() => setIsFemale(true)}
                        {...register("sexe", {
                          required: "Veuillez sélectionner un sexe",
                        })}
                        value={"femme"}
                      />{" "}
                      {t("Femme")}
                    </label>
                  </p>
                  <p className="mx-1 mx-lg-3">
                    <label>
                      <input
                        type="radio"
                        value={"homme"}
                        onChange={() => setIsFemale(false)}
                        {...register("sexe", {
                          required: "Veuillez sélectionner un sexe",
                        })}
                      />{" "}
                      {t("Homme")}
                    </label>
                  </p>
                </div>
                <p
                  className=""
                  style={{
                    // width: "500px",
                    color: " red",
                  }}
                >
                  {" "}
                  &nbsp; {errors.sexe && errors.sexe.message}
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg mb-4 space-mesure">
            <div className="bg-white mx-2 me-md-3 rounded  pb-3 ">
              <div className="d-flex justify-content-between mb-3">
                <p className="title mb-4 mt-4">{t("MESURES CLIENT")}</p>
              </div>
              <div className="">
                <>
                  <div className="d-flex justify-content-center ">
                    <button
                      type="button"
                      className="btn btn-danger mb-2"
                      onClick={addCustomField}
                      style={{ width: "46px" }}
                    >
                      <FiPlus />
                    </button>
                  </div>
                  <br />
                  {showController &&
                    customFields.map((field, index) => (
                      <div
                        key={index}
                        className="d-flex justify-content-between smallDeviceform my-4  "
                      >
                        <Controller
                          name={`mesure_client[${index}].nomMesure`}
                          control={control}
                          defaultValue={field.nomMesure}
                          render={({ field }) => (
                            <SelectInput
                              value={field.value}
                              onChange={(selectedValue) =>
                                field.onChange(selectedValue)
                              }
                            />
                          )}
                        />
                        <Controller
                          name={`mesure_client[${index}].uniteMesure`}
                          control={control}
                          defaultValue={field.uniteMesure}
                          render={({ field }) => (
                            <input
                              type="number"
                              {...field}
                              style={{ height: "40px" }}
                              placeholder="Mesure"
                              id={`mesureTitle-${index}`}
                              onChange={(e) => {
                                // Limiter la saisie à 3 caractères
                                if (e.target.value.length > 3) {
                                  e.target.value = e.target.value.slice(0, 3);
                                }
                                // Mettre à jour la valeur dans React Hook Form
                                field.onChange(e);
                              }}
                            />
                          )}
                        />
                        <button
                          type="button"
                          className="btn btn-danger mb-2"
                          onClick={() => removeCustomField(index)}
                          style={{ width: "46px" }}
                        >
                          -
                        </button>
                      
                      </div>
                    ))}
                </>
              </div>
            </div>
            <div className="container">
              <button
                className="validateButton mt-2"
                type="submit"
                style={{ width: "91%", marginLeft: "16px" }}
              >
                {isLoading ? (
                  <div
                    className="spinner-border text-white"
                    role="status"
                  ></div>
                ) : (
                  t("Enregistrer")
                )}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddClientInCommandeHomme;
