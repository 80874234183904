import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { isValidMobilePhone, isValidText } from "../../utils/form_control";
import { useAddClientMutation, useGetClientsQuery } from "../../redux/features/clientFeature";
import { useDispatch, useSelector } from "react-redux";
import { IoIosArrowBack } from "react-icons/io";
import { addNewClient } from "../../redux/slices/clientSlice";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";

const AddClientInCommande = ({ closeModal, showModal, data, setAddedClientId }) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useSelector((state) => state.UserSlice?.info);
  const { refetch } = useGetClientsQuery(currentUser?.id);
  const dispatch = useDispatch();
  const [addClient] = useAddClientMutation();
  const { t } = useTranslation();
  const [customFields, setCustomFields] = useState([
    { nomMesure: "" },
    { uniteMesure: "" },
  ]);

  const handleAddClient = async (data) => {
    try {
      const response = await addClient({
        nom_complet: data.nom_complet,
        telephone: data.telephone,
        createdBy: currentUser?.id,
      });

      if (response.error) {
        throw new Error(response.error.message);
      }

      return response.data; // Return the added client data
    } catch (error) {
      console.error("Error adding client:", error);
      throw error;
    }
  };

  const verificate = async (data) => {
    let errorFields = [];

    if (!isValidText(data?.nom_complet)) {
      errorFields.push({
        field: "nom_complet",
        message: "Veuillez saisir un nom valide !",
      });
    }
    if (!isValidMobilePhone(data?.telephone)) {
      errorFields.push({
        field: "telephone",
        message: "Veuillez saisir un numéro valide !",
      });
    }

    if (errorFields?.length) {
      errorFields?.map((err) => {
        let { field, message } = err;
        setError(field, { message });
        return err;
      });
    } else {
      try {
        setIsLoading(true);

        // Fetch clients data
        const { data: clientsData } = await refetch(); 

        if (Array.isArray(clientsData)) {
          // Check if client already exists
          const clientExists = clientsData.find(
            (client) =>
              client.nom_complet.toLowerCase() === data.nom_complet.toLowerCase() &&
              client.telephone === data.telephone
          );

          if (clientExists) {
            toast.error("Ce client existe déjà !");
          } else {
            // Add client if it doesn't exist
            const addedClient = await handleAddClient(data);

            if (addedClient) {
              dispatch(addNewClient(addedClient));
              toast.success("Client ajouté avec succès !");
              setAddedClientId(addedClient.id);
              closeModal(true);
            } else {
              toast.error("Erreur lors de l'ajout du client !");
            }
          }
        } else {
          toast.error("Erreur lors de la récupération des données des clients !");
        }
      } catch (error) {
        console.error("Error verifying and adding client:", error);
        toast.error("Erreur lors de l'ajout du client !");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title style={{ color: "red", textAlign: "center" }}>
          {t("Ajout client")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={handleSubmit(verificate)}
          className="add-client row my-4 pb-4 fs-90 text-start"
        >
          <div className="col-lg space-client mt-3">
            <div className="bg-white mx-4 ms-md-3 rounded">
              <div className="">
                <div className="">
                  <p className="text-form">{t("Nom complet")}</p>
                  <input
                    {...register("nom_complet", {
                      required: "Champ obligatoire",
                    })}
                    type="text"
                    className="form-control"
                    style={{ height: "40px" }}
                    error={errors?.nom_complet}
                  />

                  {errors?.nom_complet && (
                    <p style={{ color: "red" }}>
                      {errors.nom_complet?.message}
                    </p>
                  )}
                </div>
                <div className="">
                  <p className="text-form">{t("Téléphone")}</p>
                  <input
                    {...register("telephone", {
                      required: "Champ obligatoire",
                      maxLength: {
                        value: 9,
                        message:
                          "Le numéro de téléphone doit être de 9 chiffres.",
                      },
                    })}
                    type="number"
                    className={`form-control ${
                      errors.telephone ? "is-invalid" : ""
                    }`}
                    style={{ height: "40px" }}
                  />

                  {errors.telephone && (
                    <p style={{ color: "red" }}>{errors.telephone?.message}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg mb-4 space-mesure">
            <div className="container">
              <button
                className="validateButton mt-2"
                type="submit"
                style={{ width: "91%", marginLeft: "16px" }}
              >
                {isLoading ? (
                  <div className="spinner-border text-white" role="status"></div>
                ) : (
                  t("Enregistrer")
                )}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddClientInCommande;
