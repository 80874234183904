import React, { useState, useEffect, useRef  } from "react";
import Layout from "./templateMobile/TemplateMobile";
import { useNavigate, useParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { FiPlus, FiMinus } from "react-icons/fi";
import "./css/profilTayeur.css";
import {
  useGetParametreByUserQuery,
  useGetParametreQuery,
  useUpdateParametreMutation,
} from "../redux/features/paramettreFeature";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { PROFIL_PATH } from "../routes/path/navigation_path";
import { updateParametresInList } from "../redux/slices/parametreSlice";
import { useTranslation } from "react-i18next";


function ModificationParametreMesure() {
  const currentUser = useSelector((state) => state?.UserSlice?.info);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {id} = useParams();
  console.log({id});
  const navigate = useNavigate();
  const [mesures, setMesures] = useState([]);
  const [updateParametre] = useUpdateParametreMutation();
  const { data: getParamettre, error } = useGetParametreQuery(id);


  useEffect(() => {
    // Mettez à jour l'état local des mesures lorsque les données sont récupérées
    if (getParamettre) {
      setMesures(getParamettre?.mesure_title || []);
    }
  }, [getParamettre]);
  const [isLoading, setIsLoading] = useState(false);
  const leftContent = (
    <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
      <IoIosArrowBack size={24} />
    </div>
  );

  const centerContent = (
    <div className="titre-header">
      <span>{t("Modification paramètres")}</span>
    </div>
  );
  const rightContent = null;

  const ajouterMesure = () => {
    const newMesures = [...mesures, ""];
    setMesures(newMesures);
  };

  const supprimerMesure = (index) => {
    const updatedMesures = mesures.filter((_, i) => i !== index);
    setMesures(updatedMesures);
  };

  const validerMesures = async () => {
    try {
      // Set loading to true when starting the operation
      setIsLoading(true);

      const response = await updateParametre({
        id: id,
        body: {
          mesure_title: mesures,
        },
      });

      if (response && response.data) {
        console.log("Mesures mises à jour avec succès !");
        toast.success("Mesures mises à jour avec succès !");
        dispatch(updateParametresInList(response?.data));
        setTimeout(() => {
          navigate(PROFIL_PATH);
        }, 1000);
      } else {
        console.error("La réponse de l'API n'est pas conforme :", response);
        toast.error("Erreur lors de la mise à jour des mesures.");
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour des mesures :", error);
    } finally {
      // Reset the loading state after the operation (whether successful or not)
      setIsLoading(false);
    }
  };


  return (
    <div>
      <Layout
        leftContent={leftContent}
        centerContent={centerContent}
        rightContent={rightContent}
      >
        <div className="container" style={{marginTop:"100px"}}>
          <ToastContainer />
          <div className="d-flex flex-column">
            {mesures?.map((mesure, index) => (
              <div
                key={index}
                className="row d-flex justify-content-between mesure-input mb-4"
              >
                <div className="col-10">
                  <input
                    type="text"
                    placeholder={`Nom de la mesure ${index + 1}`}
                    value={mesure}
                    onChange={(e) => {
                      const updatedMesures = [...mesures];
                      updatedMesures[index] = e.target.value;
                      setMesures(updatedMesures);
                    }}
                    className="form-control mr-2"
                    autoFocus={index === mesures.length - 1}
                  />
                </div>
                <div className="col-2">
                  <button
                    type="button"
                    onClick={() => supprimerMesure(index)}
                    className="btn btn-danger"
                  >
                    <FiMinus />
                  </button>
                </div>
              </div>
            ))}

            <div className="d-flex justify-content-center mb-4">
              <button
                type="button"
                className="btn btn-danger mb-2"
                style={{ width: "46px" }}
                onClick={ajouterMesure}
              >
                <FiPlus />
              </button>
            </div>
            <div className="d-flex justify-content-center" style={{ paddingBottom:'200px' }} >
            <button className="validateButton mt-2" type="submit" style={{ width: "91%", marginLeft: '16px'}} 
            
             disabled={mesures.length === 0}
             onClick={validerMesures}> 
            {isLoading ? (
               <div
               className="spinner-border text-white"
               role="status"
             ></div>
           ) : (
             t("Valider")
           )}
                </button>
              {/* <button
                className="btn btn-danger"
                disabled={mesures.length === 0}
                onClick={validerMesures}
              >
                Valider les modifications
              </button> */}
            </div>
            <br />
      
          </div>
   
        </div>
      </Layout>
    </div>
  );
}

export default ModificationParametreMesure;
