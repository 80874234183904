import React, { useEffect, useState } from 'react'
import { Navigate, Outlet,useLocation, useNavigate } from 'react-router-dom';
import { LOGIN_PATH } from './path/navigation_path';
import { useSelector } from 'react-redux';


const PrivateRoutes = () => {
  const location = useLocation();


    const currentUser = useSelector(state => state.UserSlice?.info)
   

  return (
   currentUser !== null ? <Outlet/> : <Navigate to={LOGIN_PATH} state={{ from: location }} replace/>
  )
}

export default PrivateRoutes