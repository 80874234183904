// export const DEFAULT_API = 'http://127.0.0.1:8000/api/v1/'

import { PARAMETRE_PATH } from "./path/navigation_path";

// export const DEFAULT_API_IMAGE = 'http://127.0.0.1:8000/api'
// export const DEFAULT_API = "https://tayeur.onrender.com/api/v1/";
// export const DEFAULT_API_IMAGE = "https://tayeur.onrender.com/api";

export const DEFAULT_API = "https://gestion.tayeur.com/api/v1/";
export const DEFAULT_API_IMAGE = "https://gestion.tayeur.com/api";

// export const DEFAULT_API = 'http://167.99.253.190:8086/api/v1/'

export const REGISTER_API_ROUTE = DEFAULT_API + "users/";
export const LOGIN_API_ROUTE = DEFAULT_API + "login/";
export const UPDATE_USER_API_ROUTE = DEFAULT_API + "users/"; // + {id}/
export const GET_USER_API_ROUTE = DEFAULT_API + "users/"; // + {id}/
export const CHANGE_USER_PASSWORD_API_ROUTE = DEFAULT_API + "user/password/"; // + {id}/

export const CLIENT_API_ROUTE = DEFAULT_API + "clients/user/";
export const ADD_CLIENT_API_ROUTE = DEFAULT_API + "clients";
export const UPDATE_CLIENT_API_ROUTE = DEFAULT_API + "clients/"; // + {id}/
export const DETAIL_CLIENT_API_ROUTE = DEFAULT_API + "clients/"; // + {id}/
export const DELETE_CLIENT_API_ROUTE = DEFAULT_API + "clients/";

export const GENRE_CLIENT_API_ROUTE = DEFAULT_API + "genres";

export const MESURE_API_ROUTE = DEFAULT_API + "models/genre/{genre_id}/";

const PARAMETRES_PATH = DEFAULT_API + "parametres/";
export const PARAMETRE_API = {
  GETBYUSER: (id) => PARAMETRES_PATH + "user/" + id + "/",
  ADD: () => PARAMETRES_PATH,
  GET: (id) => PARAMETRES_PATH + id + "/",
  EDIT: (id) => PARAMETRES_PATH + id + "/",
  DELETE: (id) => PARAMETRES_PATH + id + "/",
};

export const USER_API_ROUTE = DEFAULT_API + "user/";

export const UPDATE_COMMANDE = DEFAULT_API + "updateMesuresCommandesV2/" ;

const COMMANDE_PATH = DEFAULT_API;


export const COMMANDE_API = {
  GETBYUSER: (id) => COMMANDE_PATH + "commandesV2/user/" + id,
  ADD: () => COMMANDE_PATH + "ajoutMesuresCommandesV2/",
  EDIT: (commande_id) => COMMANDE_PATH + "updateMesuresCommandesV2/" + commande_id + "/",
  DELETE: (id) => COMMANDE_PATH + "commandesV2/" + id + "/",  
  GET: (id) => COMMANDE_PATH + "commandeV2_detail/" + id + "/",
  // GETALL: () => COMMANDE_PATH,  // Cette ligne est commentée, mais peut être activée si nécessaire
};
// export const COMMANDE_API = {
//   GETBYUSER: () => COMMANDE_PATH + "commandesv2/",
//   ADD: () => COMMANDE_PATH + "ajoutMesuresCommandesV2/",
//   EDIT: (id) => COMMANDE_PATH + "commandesv2/" + id + "/",
//   DELETE: (id) => COMMANDE_PATH + id + "/",
//   GET: (id) => COMMANDE_PATH + "commandeV2_detail/" + id ,
//   // GETALL: () => COMMANDE_PATH,
// };

export const ADDCOMMANDES_API_ROUTE = DEFAULT_API + "ajoutMesuresCommandesV2/"

export const DELETECOMMANDES_API_ROUTE = DEFAULT_API + "commandesv2/{id}/"

export const COMMANDEV2_DETAILS_API_ROUTE = DEFAULT_API + "commandeV2_detail/{id}/"


export const GETCOMMANDES_API_ROUTE = DEFAULT_API + "commandesV2/"
// export const ADD_COMMANDE_API_ROUTE = DEFAULT_API + 'commandes/'
// export const COMMANDE_API_ROUTE = DEFAULT_API +'commandes/user/'
// export const DELETE_COMMANDE_API_ROUTE = DEFAULT_API +'commandes/'
// export const UPDATE_COMMANDE_API_ROUTE = DEFAULT_API +'commandes/'

export const ADD_HORRAIRE_API_ROUTE = DEFAULT_API + "horraires/";
export const HORRAIRE_API_ROUTE = DEFAULT_API + "horraires/user/";
export const DELETE_HORRAIRE_API_ROUTE = DEFAULT_API + "horraires/";
export const UPDATE_HORRAIRE_API_ROUTE = DEFAULT_API + "horraires/";

export const ADD_COMPTABILITE_API_ROUTE = DEFAULT_API + "transaction/";
export const COMPTABILITE_API_ROUTE = DEFAULT_API + "comptabilite/";
export const DELETE_COMPTABILITE_API_ROUTE = DEFAULT_API + "comptabilite/";
export const UPDATE_COMPTABILITE_API_ROUTE = DEFAULT_API + "transaction/";

export const ADD_ENTREE_API_ROUTE = DEFAULT_API + "entree/";
export const ENTREE_API_ROUTE = DEFAULT_API + "entree/";

export const GET_ALBUM_API_ROUTE = DEFAULT_API + "album/user/";
export const ADD_ALBUM_API_ROUTE = DEFAULT_API + "album/";

export const PHOT_IN_ALBUM_API_ROUTE = DEFAULT_API + "photos/album";

export const ADD_PHOT_IN_ALBUM_API_ROUTE = DEFAULT_API + "photos/album";










{/* <div className="col-xl-12 col-lg-12 col-md-10 col-sm-12 col-12">
<label className="mesure-du-client" style={{ display: "flex", justifyContent: "center"}}> 
  <img src={selectedModel.image} style={{ width: "75px", height: "75px", borderRadius: "50px"}}/> 
</label>
</div> */}