import React from "react";
import './css/commandeItemHome.css'
import { useTranslation } from "react-i18next";


const CommandeItemHome = ({id, client, model, date_commande, image }) => {
   
  
    return (
      <div className="troisElements">
        <div>
          {/* <p> {id}</p> */}
          <p style={{
            fontSize: 14,
            fontWeight: 500
          }}> {client}</p>
          {/* <p> {model}</p> */}
          <p style={{
            fontSize: 10,
            fontWeight: 500
          }}>{date_commande}</p>
        </div>
        <img
          style={{
            width: "60px",
            height: "60px",
            borderRadius: "50px",
            objectFit: "cover"
          }}
          src={image}
        />
          
      </div>
    );
  };
  

export default CommandeItemHome;
