import React, { useEffect, useState } from "react";
import { FiPlus, FiUpload } from "react-icons/fi";
import { useSelector } from "react-redux";
import { json, useNavigate } from "react-router-dom";
import { COMMANDES_PATH } from "../../routes/path/navigation_path";
import Layout from "../../component/templateMobile/TemplateMobile";
import { IoIosArrowBack } from "react-icons/io";
import "./addCommande.css";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import { useAddCommandeMutation } from "../../redux/features/commandeFeature";
import { useAddTransactionMutation } from "../../redux/features/transaction";
import { useGetClientsQuery } from "../../redux/features/clientFeature";
// import AddClientInCommande from "./AddClientInCommande";
import CircularWithValueLabel from "../../utils/Material-ui/composantChargement";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import mixpanel from "mixpanel-browser";
import { useTranslation } from "react-i18next";
import { DropzoneArea } from "react-dropzone";
import AddClientInCommande from "./AddClientInCommande";
import AddClientInCommandeHomme from "./AddClientInCommande";

const ClientHommes = () => {
  const navigate = useNavigate();
  const [addCommande] = useAddCommandeMutation();
  const [addTransaction] = useAddTransactionMutation();
  const [showModal, setShowModal] = useState(false);
  const [montantRestant, setMontantRestant] = useState(0);
  const [montantPayeFromApiCompta, setMontantPayeFromApiCompta] = useState(0);
  const [montantState, setMontantState] = useState();
  const currentUser = useSelector((state) => state?.UserSlice?.info);
  const { data } = useGetClientsQuery(currentUser?.id);
  const [avanceError, setAvanceError] = useState(null);
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const listClient = useSelector((state) => state.clients?.clientsList);
  const [addedClientId, setAddedClientId] = useState(null);

  const [errorFields, setErrorFields] = useState([]);
  const [dateError, setDateError] = useState(null);

  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [isInvalidInput, setIsInvalidInput] = useState(false);
  const [isInvalidPrice, setIsInvalidPrice] = useState(false);
  const [isInvalidAvance, setIsInvalidAvance] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  // const [dateError, setDateError] = useState(null);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [validateTissuName, setValidateTissuName] = useState(false);

  const options = data?.map((item) => ({
    value: item.id,
    label: item.nom_complet,
  }));

  const handleTissu = (e) => {
    const inputValue = e.target.value + e.key;
    if (
      e.key !== "Backspace" &&
      e.key !== "Tab" &&
      !/^[a-zA-ZÀ-ÿ\s]*$/.test(inputValue)
    ) {
      e.preventDefault();
      setValidateTissuName(true);
    } else {
      setValidateTissuName(false);
    }
  };

  const handleFileUpload = (files) => {
    const file = files[0];
    setSelectedImage(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Fin
  useEffect(() => {
    const montant = parseInt(montantState);
    const montantPaye = montantPayeFromApiCompta;
    const montantRestant = montant - montantPaye;
    setMontantRestant(montantRestant);
  }, [montantState, montantPayeFromApiCompta]);

  const validateAge = (value) => {
    if (!value) return true;
    if (isNaN(value) || value < 0 || value > 99 || !/^\d{1,2}$/.test(value)) {
      return false;
    }
    return true;
  };

  const validatePrice = (value) => {
    if (!value) return true;
    if (isNaN(value) || parseFloat(value) < 1 || !/^[1-9]\d*$/.test(value)) {
      return false;
    }
    return true;
  };

  const validateAvance = (value) => {
    if (!value) return true;
    if (isNaN(value) || parseFloat(value) < 1 || !/^[1-9]\d*$/.test(value)) {
      return false;
    }
    return true;
  };

  const handlePrice = (e) => {
    const inputValue = e.target.value + e.key;
    if (e.key !== "Backspace" && (isNaN(e.key) || !validatePrice(inputValue))) {
      e.preventDefault();
      setIsInvalidPrice(true);
    } else {
      setIsInvalidPrice(false);
    }
  };
  const handleAvance = (e) => {
    const inputValue = e.target.value + e.key;
    if (
      e.key !== "Backspace" &&
      e.key !== "Tab" &&
      (isNaN(e.key) || !validateAvance(inputValue))
    ) {
      e.preventDefault();
      setIsInvalidAvance(true);
    } else {
      setIsInvalidAvance(false);
    }
  };

  // const handleNumericInput = (e) => {
  //   const inputValue = e.target.value + e.key;
  //   if (
  //     e.key !== "Backspace" &&
  //     e.key !== "Tab" &&
  //     (isNaN(e.key) || !validateAge(inputValue))
  //   ) {
  //     e.preventDefault();
  //     setIsInvalidInput(true);
  //   } else {
  //     setIsInvalidInput(false);
  //   }
  // };
  const handleNumericInput = (e) => {
    const inputValue = e.target.value + e.key;
    if (
      e.key !== "Backspace" &&
      e.key !== "Tab" &&
      isNaN(e.key) &&
      e.key !== "."
    ) {
      e.preventDefault();
      setIsInvalidInput(true);
    } else {
      setIsInvalidInput(false);
    }
  };

  const submit = (data) => {
    setIsLoading(true);

    if (data.modele && data.modele[0]) {
      console.log(selectedImage, "fffffff");
      data.clientId = selectedClient?.value;
      const newData = new FormData();
      // const dateLivraison = new Date(data.date_livraison);
      const formattedDate = format(selectedDate, "yyyy-MM-dd'T'HH:mm");
      newData.append("clientId", parseInt(selectedClient?.value));
      newData.append("nom_tissu", data.nom_tissu);
      newData.append("metre_tissu", parseInt(data.metre_tissu));
      newData.append("montant", parseInt(montantState));
      newData.append("montant_paye", montantPayeFromApiCompta);
      newData.append("date_livraison", formattedDate);
      newData.append("modele", data.modele[0]);
      newData.append("createdBy", currentUser.id);

      addCommande({ body: newData })
        // .registerCommande(newData)
        .unwrap()
        .then((res) => {
          // console.log(res)
          const newTransaction = {
            createdBy: res?.createdBy,
            commande: res.id,
            montant_paye: montantPayeFromApiCompta,
          };

          // console.log(newTransaction)
          addTransaction({ body: newTransaction })
            .unwrap()
            .then((res) => {
              setMontantPayeFromApiCompta(res.montant_paye);
              console.log({ REPONSE_TRANSACTION: res });
            })
            .catch(() => {
              // console.log(error);
              toast.error("Erreur lors de l'ajout de la commande");
            });
          toast.success("commande ajoute avec succés !");
          reset();
          navigate(COMMANDES_PATH);
        })
        .catch(() => {
          // console.log(err);
          setError("message", { message: "Veuillez selecetionnez un client" });
          toast.error("Erreur lors de l'ajout de la commande");
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      data.clientId = selectedClient?.value;
      data.createdBy = currentUser.id;
      let { clientId } = data;
      const dateLivraison = new Date(data.date_livraison);
      // const isoDate = dateLivraison.toISOString();
      console.error("Aucune image sélectionnée");
      const newCommande = {
        clientId: parseInt(clientId),
        nom_tissu: data.nom_tissu,
        metre_tissu: parseInt(data.metre_tissu),
        montant: parseInt(montantState),
        montant_paye: parseInt(montantPayeFromApiCompta),
        restant: montantRestant,
        date_livraison: data?.date_livraison,
        createdBy: currentUser.id,
      };

      // console.log(newCommande)

      addCommande({ body: newCommande })
        .unwrap()
        .then((res) => {
          // console.log({ commande: res })
          // console.log(res)
          const newCommande = {
            createdBy: res?.createdBy,
            commande: res.id,
            montant_paye: parseInt(montantPayeFromApiCompta),
          };
          addTransaction({ body: newCommande })
            .unwrap()
            .then((res) => {
              // console.log({ REPONSE_COMMANDE: res })
              setMontantPayeFromApiCompta(res.montant_paye);
            })
            .catch((error) => {
              console.log(error);
            });
          mixpanel.track("Ajout de commande", { "ID de la commande": res.id });
          toast.success("Ajout commande réussi");
          reset();
          navigate(COMMANDES_PATH);
        })
        .catch((err) => {
          console.log(err);
          setError("message", { message: "Erreur lors de l'ajout commande" });
          toast.error("Erreur lors de l'ajout commande");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    if (errorFields.length) {
      errorFields.map((err) => {
        let { field, message } = err;
        setError(field, { message });
        return err;
      });
    } else {
      verificate(data); // Appel de la méthode verificate ici
    }
  };

  const verificate = (data) => {
    // console.log({ data: Date.parse(data.date_livraison) });
    let errorFields = [];

    if (!data.clientId) {
      errorFields.push({
        field: "clientId",
        message: "Veuillez sélectionner un client",
      });
    }
    if (!data.telephone) {
      errorFields.push({
        field: "telephone",
        message: "Ce numéro n'est pas valide !",
      });
    }

    if (errorFields.length) {
      errorFields.map((err) => {
        let { field, message } = err;
        setError(field, { message });
        return err;
      });
    } else {
      setMontantPayeFromApiCompta(data.montant_avance);
      submit(data);
    }
  };

  const handleGoBack = () => {
    navigate(COMMANDES_PATH);
  };

  const leftContent = <IoIosArrowBack size={20} onClick={handleGoBack} />;

  // Ajoutez cette fonction au-dessus de votre composant ClientHommes
  const handleValidationButtonClick = () => {
    handleSubmit(submit)();
  };

  // Intégrez cette fonction dans votre centre de contenu (centerContent)
  const centerContent = null;

  const rightContent = (
    <div className="titre-header">
      <button
        className="validateButton mt-2"
        type="button" // Changez le type de "submit" à "button"
        style={{ width: "91%", marginLeft: "16px" }}
        onClick={handleValidationButtonClick} // Ajoutez cet événement onClick
      >
        {isLoading ? (
          <div className="spinner-border text-white" role="status"></div>
        ) : (
          t("Valider")
        )}
      </button>
    </div>
  );

  // console.log(Date.now());
  return (
    <Layout
      leftContent={leftContent}
      centerContent={centerContent}
      rightContent={rightContent}
    >
      <div className="container ajout-client">
        <ToastContainer />
        {/* {JSON.stringify(data.modele)}
      {JSON.stringify(selectedImage)}  */}
        <div className="card-input">
          <form onSubmit={handleSubmit(submit)}>
            <div className="add-commande-container">
              <div className="add-commande-client-section">
                {/* Client */}
                <div className="row section-content displaySelectAndAddClient">
                  <div className="row m-1">
                    {/* <h3 className="section-title">Client</h3> */}
                    <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10">
                      <label htmlFor="clientId" className="labelSmalDevice">
                        {t("Nom client")}
                      </label>
                    </div>
                    <div
                      style={{ textAlign: "center", marginTop: "5px" }}
                    ></div>
                    <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10">
                      <Select
                        className="onSmallDevicce"
                        name="clientId"
                        id="clientId"
                        options={options}
                        placeholder={t("Choisissez un client")}
                        isClearable={true}
                        {...register("clientId")}
                        value={selectedClient || null}
                        onChange={(client) =>
                          client?.value !== "+" && setSelectedClient(client)
                        }
                        required
                      />

                      {errors.clientId && (
                        <p className="error-msg text-center">
                          &nbsp; {errors.clientId.message}
                        </p>
                      )}
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                      <button
                        type="button"
                        className="btn btn-danger mb-2"
                        style={{ width: "40px" }}
                        onClick={openModal}
                      >
                        <FiPlus />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center mb-4">
                  <AddClientInCommandeHomme
                    closeModal={closeModal}
                    showModal={showModal}
                    data={data}
                    setAddedClientId={setAddedClientId}
                  />
                </div>
                <div className="mesure-card">
                    <div>
                        <h6 className="mesure-client" style={{ paddingTop: "20px", paddingBottom: "10px" }}>Mesures du client: BAYE LAHAT</h6>
                    </div>
                    <hr style={{ color: "#DDE5E9", fontSize:"3px"}}/>
                  <div className="d-flex justify-content-between mb-3">
                    <p>COU :</p>
                    <input
                      type="number"
                      {...register("cou", {})}
                      className="inputMesure"
                    />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <p>EPAULE :</p>
                    <input
                      type="number"
                      {...register("epaule")}
                      className="inputMesure"
                    />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <p>MANCHES :</p>
                    <input
                      type="number"
                      {...register("longueur_manche")}
                      className="inputMesure"
                    />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <p>CEINTURE :</p>
                    <input
                      type="number"
                      {...register("ceinture")}
                      className="inputMesure"
                    />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <p>TOUR DE BRAS :</p>
                    <input
                      type="number"
                      {...register("tour_bras")}
                      className="inputMesure"
                    />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <p>TOUR DE HANCHES :</p>
                    <input
                      type="number"
                      {...register("hanche")}
                      className="inputMesure"
                    />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <p>TOUR DE CUISSE :</p>
                    <input
                      type="number"
                      {...register("cuisse")}
                      className="inputMesure"
                    />
                  </div>
                  <div className="d-flex justify-content-between mb-3">
                    <p>LONGUEUR BOUBOU :</p>
                    <input
                      type="number"
                      {...register("longueur_boubou")}
                      className="inputMesure"
                    />
                  </div>
                </div>
              </div>
            </div>
            <br></br>
            <br></br>
            <br></br>
          </form>
        </div>
        {/* <AddClientForm showModal={showModal} setShowModal={setShowModal} /> */}
      </div>
    </Layout>
  );
};

export default ClientHommes;
