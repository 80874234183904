import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import { baseApi } from "./apiManager";
import storage from 'redux-persist/lib/storage';
import UserSlice from "./slices/UserSlice";
import clientSlice from "./slices/clientSlice";
import commandesSlice from './slices/commandeSlice';
import ParametresSlice from "./slices/parametreSlice";
import modelSlice from "./slices/modelSlice";
// import genreSlice from "./slices/genreSlice";
// import TransactionSlice from "./slices/transactionSlice";


const persistConfig = {
  key: "tayeur-gestion",
  storage,
};

const rootReducer = combineReducers({
 UserSlice,
 clientSlice,
 commandesSlice,
 ParametresSlice,
 modelSlice,
//  genreSlice,
//  TransactionSlice,
//   panier: persistReducer(persistConfig),
  // commande:persistReducer(persistConfig, panierReducer),
 
  [baseApi.reducerPath]: baseApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat([baseApi.middleware]);
  },
  devTools: true,
});

export const persistor = persistStore(store);
