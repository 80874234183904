export const DEFAULT_PATH = "*";

/* ===================== AUTH PATHS =================== */
export const LOGIN_PATH = "/login";
export const REGISTER_PATH = "/register";

/* ===================== HOME PATH =================== */
export const ACCUEIL_PATH = "/";

/* ===================== COMMANDES PATHS =================== */
export const COMMANDES_PATH = "/commandes";
export const ADD_COMMANDE_PATH = "/client-femmes/:genreId";
export const DETAIL_COMMANDE_PATH = "/detail-commande/:id";
export const UPDATE_COMMANDE_PATH = "/update-commande/:id";
export const DETAIL_COMPTABLITE_PATH = "/detail-comptabilite/:id";

export const ADD_COMMANDE_ID_PATH = "/add-commande/:clientId";

/* ===================== CLIENTS PATHS =================== */
export const CLIENTS_PATH = "/clients";
export const UPDATE_CLIENTS_PATH = "/clients/:id";
export const ADD_CLIENT_PATH = "/add-client";
export const DETAIL_CLIENT_PATH = "/detail-client/:id";

/* ===================== COMPATIBILITÉ PATHS =================== */
export const COMPTABLITE_PATH = "/comptablite";

/* ===================== STOCK PATHS =================== */
export const STOCK_PATH = "/stock";

/* ===================== EMPLOYES PATHS =================== */
export const EMPLOYEES_PATH = "/employes";

/* ===================== GALERIE PATHS =================== */
export const GALERIE_PATH = "/galeries";
export const ADD_GALERIE_PATH = "/add-galerie";
export const DETAIL_GALERIE_PATH = "/detail-galerie/:id";

export const PARAMETRE_PATH = "/parametres";



export const DETAIL_ENTREE_PATH = "/detail-entree/:id";

export const PROFIL_PATH = "/profil"
export const PERSONALISER_PATH = "/personaliser";

export const NOTIFICATION_PATH = "/notification"

export const INFOSATELIER_PATH = "/info-atelier"

export const MODIF_PARAMETRE_PATH = "/modif-parametre-atelier/:id"

export const HISTORIQUE_PATH = "/historique-commande/:id"

export const ALL_COMMANDE_BY_CLIENT_PATH = "/all_c_client/:id"

export const TUTORIEL_PATH = "/tutoriel"

export const MESURES_FEMMES = "/genres/:genreId"

export const MESURE_DETAIL_PAGE = ""

export const MESURES_HOMMES = "/mesure-hommes"

export const CLIENT_HOMMES = "/client-hommes"

export const CLIENT_FEMMES = "/client-femmes"

