import React from 'react'

const MesureDetailPage = () => {
  return (
    <div>
      <p>test</p>
    </div>
  )
}

export default MesureDetailPage
