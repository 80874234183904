import React from 'react'
import '../utils/GlobalStyle.css'


const ClientInfoComponent = ({text, Texvalue}) => {
    return (
      <div style={{display:'flex', justifyContent:'space-between' , padding: '10px'}}>
          <span className='small-title'>{text}</span>
          <h6 className='small-texte'>{Texvalue}</h6>
      </div>
    )
  }
  
  export default ClientInfoComponent