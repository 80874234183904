import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ApiMananger } from "../apiManager";
import { MESURE_API_ROUTE } from "../../routes/api_routes";


export const modelMesureApi = ApiMananger.injectEndpoints({
  baseQuery: fetchBaseQuery({ baseUrl: MESURE_API_ROUTE }),
  tagTypes: ['Modeles'],
  keepUnusedDataFor: 30,
  endpoints: (builder) => ({
    getModelMesure: builder.query({
      query: (genreId) => `models/genre/${genreId}`,
      providesTags: ["Modeles"],
    }),
    getModelMesureById: builder.query({
      query: (genreId) => `/genres/${genreId}`,
      keepUnusedDataFor: 5
    }),
  }),
});

export const {
  useGetModelMesureQuery,
  useGetModelMesureByIdQuery
} = modelMesureApi;





   // query: (genreId) => {
      //   return {
      //     url: `models/genre/${genreId}`,
      //     method: 'GET',
      //   };
      // },