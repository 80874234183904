import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  currentTransaction: {},
  transactionList: [],
  allTransactionList: [],
};
const TransactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    addNewTransaction: (state, { payload }) => {
      state.transactionList = [payload, ...state.transactionList];
      state.allTransactionList = [payload, ...state.allTransactionList];
      state.montant_paye = payload.nouveauMontantPaye;
    },
    setTransactionList: (state, { payload }) => {
      state.transactionList = payload;
      state.allTransactionList = payload;
    },
    updateMontantnn: (state, { payload }) => {
      state.montant_paye = payload.nouveauMontantPaye; // Assurez-vous d'ajuster cela en fonction de votre structure de données
    },
  },
});

export const {
  addNewTransaction,
  setCurrentTransaction,
  updateMontantnn,
} = TransactionSlice.actions;
export default TransactionSlice.reducer;
