import { LOGIN_API_ROUTE, REGISTER_API_ROUTE, UPDATE_USER_API_ROUTE,GET_USER_API_ROUTE } from "../../routes/api_routes";
import { ApiMananger } from "../apiManager";

const userApi = ApiMananger.injectEndpoints({
    overrideExisting: true,
    endpoints: (build) => ({
      login: build.mutation({
        query(body,) {
          return {
            url: LOGIN_API_ROUTE,
            method: "POST",
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
              // "X-CSRFToken": token,
            },
            body,
          };
        },
        invalidatesTags: ["User"],
      }),
      registerUser: build.mutation({
        query(body) {
          return {
            // url: "/users/",
            url: REGISTER_API_ROUTE,
            method: "POST",
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
              // "X-CSRFToken": token,
            },
            body,
          };
        },
        invalidatesTags: ["User"],
      }),
      updateUser: build.mutation({
        query(data) {
          const { id, body } = data;
          // console.log(data)
          return {
            url: UPDATE_USER_API_ROUTE + id ,
            method: "PUT",
            body,
          };
        },
        invalidatesTags: ["User"],
      }),
      getUserById: build.query({
        query: (id) => {
          // console.log({GET_USER_API_ROUTE});
          return {
            url: GET_USER_API_ROUTE + id ,
            method: "GET"
          }
        },
        providesTags: ["User"],
      }),
    }),
  });
  export const {
    useLoginMutation,
    useRegisterUserMutation,
    useUpdateUserMutation,
    useGetUserByIdQuery,
  } = userApi;