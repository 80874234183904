import React, { useCallback, useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useForm, useFieldArray } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import { useGetCommandeQuery, useUpdateCommandeMutation } from "../../redux/features/commandeFeature";
import Layout from "../../component/templateMobile/TemplateMobile";
import "react-datepicker/dist/react-datepicker.css";
import "./css/updateCommande.css";
import { FaPlus } from "react-icons/fa";
import { LuPenLine } from "react-icons/lu";
import { RiDeleteBin6Line } from "react-icons/ri";
import AppPadding from "../../component/ui/AppPadding";

const UpdateCommande = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [updateCommande, { isLoading }] = useUpdateCommandeMutation();
  const { data: currentCommande, isLoading: currentCommandeLoading } = useGetCommandeQuery(id);
  
  const { register, handleSubmit, setValue, control } = useForm({
    defaultValues: {
      client: {
        nom_complet: "",
        telephone: "",
      },
      valeurs_mesures: [
        { mesure_nom: '', valeur: '' },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "valeurs_mesures",
  });

  useEffect(() => {
    if (currentCommande) {
      setValue("client.nom_complet", currentCommande.client_nom);
      setValue("client.telephone", currentCommande.client_phone);
      setValue("valeurs_mesures", currentCommande.mesures);
    }
  }, [currentCommande, setValue]);

  const onSubmit = useCallback(async (formValue) => {
    // return console.log(id, 'id')
    try {
      await updateCommande({
        commande_id: id,  
        body: {
          client_nom: formValue.client.nom_complet,
          client_phone: formValue.client.telephone,
          mesures: formValue.valeurs_mesures,
        },
      }).then((response) => {
        toast.success("Order updated successfully.");
        navigate(-1);
      });
    } catch (error) {
      toast.error("Error updating the order.");
    }
  }, [])

  const leftContent = (
    <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
      <IoIosArrowBack size={24} />
    </div>
  );

  const centerContent = (
    <div className="titre-header">
      <span>Modifier la commande</span>
    </div>
  );

  return (
    <Layout leftContent={leftContent} centerContent={centerContent}>
      <div style={{ margin: "20px" }}>
        <ToastContainer />
        {currentCommandeLoading ? (
          <p>Loading...</p>
        ) : (
          <div style={{ background: "white", borderRadius: "4px", padding: "15px" }}>
            <AppPadding>
          <form onSubmit={handleSubmit(onSubmit)} >
            <div className="row">
            <div className="detail_commande col-md-6 mb-4">
              <label>Nom Complet</label>
              <input
                type="text"
                {...register("client.nom_complet", { required: true })}
                className="form-control"
              />
            </div>
            <div className="detail_commande col-md-6 mb-4">
              <label>Téléphone</label>
              <input
                type="text"
                {...register("client.telephone", { required: true })}
                className="form-control"
              />
            </div>
            </div>
           
            <div>
              <label>Mesures</label>
              {fields.map((item, index) => (
                <div key={item.id} style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px"}} >
                  <input
                  className="form-control"
                    type="text"
                    placeholder="Mesure Nom"
                    {...register(`valeurs_mesures.${index}.mesure_nom`, { required: true })}
                    style={{
                      color: "#797979",
                      borderRadius: '5px',
                      height: "40px",
                      width: "90px"
                    }}
                  />
                  <div style={{display: "flex"}}>
                  <input
                  className="form-control"
                    type="text"
                    placeholder="Valeur"
                    {...register(`valeurs_mesures.${index}.valeur`, { required: true })}
                    style={{ width: "150px", marginRight: "5px"}}
                  />
                  <button type="button" className="btn btn-danger" onClick={() => remove(index)}>
                    <RiDeleteBin6Line />
                  </button>
                  </div>
                </div>
              ))}
              <button
                className="btn btn-danger mb-2"
                style={{ width: "40px" }}
                
                onClick={() => append({ mesure_nom: "", valeur: "" })}
              >
                 <FaPlus />
              </button>
            </div>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
            <button type="submit" disabled={isLoading}   className="validateButton mt-2"
         >
              Modifier
            </button>
            </div>
          </form>
          </AppPadding>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default UpdateCommande;
