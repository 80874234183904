import React, { useState, useEffect } from "react";
import { useGetCommandeQuery, useUpdateCommandeMutation } from "../../../redux/features/commandeFeature";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import './css.css';
import '../../../utils/GlobalStyle.css'
import Layout from "../../../component/templateMobile/TemplateMobile";
import { IoIosArrowBack } from "react-icons/io";
import { format } from 'date-fns';
import { useTranslation } from "react-i18next";

const Historique = () => {
  const {id} = useParams(); 
  const navigate = useNavigate();

  const { data: commande, isLoading } = useGetCommandeQuery(id);
  const { t } = useTranslation();
  // Afficher la liste des transactions
  const renderTransactions = () => {
    if (commande && commande?.transactions && commande?.transactions?.length > 0) {
        let totalMontantPaye = 0;
      return (
        <div className="mx-2">
          <p className="mx-2"><span className="big-title color-black">{t("Nom du client")}</span> : {commande.client?.nom_complet}</p>
          <p className="mx-2"><span className="big-title">{t("Prix de confection")}</span> : {commande.montant} Fcfa</p>
          <p className="mx-2"><span className="big-title">{t("Montant restant")}</span> : {commande.montant_restant} Fcfa</p>

            {commande.transactions.map((transaction) => {
                  totalMontantPaye += transaction.montant_paye;
                 return (
                    <div className="histo" key={transaction.id}>
                      <div className="d-block">
                        <p>{t("Date de transaction")} :</p>
                        <p>{format(new Date(transaction.date_transaction), 'dd/MM/yyyy HH:mm:ss')}</p>
                      </div>
                      <div className="d-block">
                        <p>{t("Montant avancé")} : {transaction.montant_paye} Fcfa</p>
                      </div>
                    </div>
                  )
                 })}
             <div className="total-montant-paye">
            <p className="mx-2">{t("Total Montant Payé")} : {totalMontantPaye} Fcfa</p>
          </div>
        </div>
      );
    } else {
      return <p>{t("Aucune transaction pour cette commande.")}</p>;
    }
  };
  return (
  //   <Layout
  //   leftContent={leftContent}
  //   centerContent={centerContent}
  //   rightContent={rightContent}
  // >
    
    <div style={{ }}>
        {/* Afficher la liste des transactions */}
        {renderTransactions()}
      </div>
  // </Layout>
  );
};

export default Historique;

