import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function SkeletonComponent() {
  return (
    <Stack spacing={2}>
      {/* For variant="text", adjust the height via font-size */}
      {/* <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> */}
      {/* For other variants, adjust the size with `width` and `height` */}
      {/* <Skeleton variant="circular" width={40} height={40} /> */}
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={30}
        width="100%"
        style={{ marginBottom: 6 }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={30}
        width="100%"
        style={{ marginBottom: 6 }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={30}
        width="100%"
        style={{ marginBottom: 6 }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={30}
        width="100%"
        style={{ marginBottom: 6 }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={30}
        width="100%"
        style={{ marginBottom: 6 }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={30}
        width="100%"
        style={{ marginBottom: 6 }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={30}
        width="100%"
        style={{ marginBottom: 6 }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={30}
        width="100%"
        style={{ marginBottom: 6 }}
      />

      {/* <Skeleton variant="rounded" width={210} height={60} /> */}
    </Stack>
  );
}
