import { COMMANDE_API, UPDATE_COMMANDE } from "../../routes/api_routes";
import { ApiMananger } from "../apiManager";

export const commandeApi = ApiMananger.injectEndpoints({overrideExisting : true,
  endpoints: (build) => ({

    getCommande: build.query({
      query: (id) => COMMANDE_API.GET(id),
      providesTags: ["Commande"],
    }),
    getCommandesByUser: build.query({
      query: (id) => COMMANDE_API.GETBYUSER(id),
      providesTags: ["Commande"],
    }),
    addCommande: build.mutation({
      query({ body }) {
        return {
          url: COMMANDE_API.ADD(),
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
          body,
        };
      },
      invalidatesTags: ["Commande"],
    }),
    updateCommande: build.mutation({
      query(data) {
        console.log(data, data)
        const { commande_id, body } = data;
        return {
          url: `${UPDATE_COMMANDE}${commande_id}/`, 
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Commande"],
    }),
    deleteCommande: build.mutation({
      query(id) {
        return {
          url: COMMANDE_API.DELETE(id),
          method: "DELETE",
        };
      },
      invalidatesTags: ["Commande"],
    }),
  }),
});

export const {
  useGetCommandeQuery,
  // useGetCommandesQuery,
   useAddCommandeMutation,
  useUpdateCommandeMutation,
  useDeleteCommandeMutation,
  useGetCommandesByUserQuery
} = commandeApi;
