import React from "react";
import Layout from "../../component/templateMobile/TemplateMobile";
import { IoIosArrowBack } from "react-icons/io";
import LanguageSelector from "../../component/Langage/LanguageSelector";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import videos from "../../assets/videos.MP4"

const Tuto = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const leftContent = (
    <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
      <IoIosArrowBack size={24} />
    </div>
  );

  const centerContent = (
    <div className="titre-header">
      <span>{t("Tutoriel")}</span>
    </div>
  );

  const rightContent = (
    <div>
      <LanguageSelector />
    </div>
  );
  return (
    <>
      <Layout
        leftContent={leftContent}
        centerContent={centerContent}
        rightContent={rightContent}
      >
        <div>
          {/* <h1 className="title-en-tete" style={{ padding: "25px" }}>Bienvenue dans Tayeur gestion</h1> */}
          <p className="title-en-tete" style={{ padding: "25px", fontSize: "20px" }}>{t("Voici comment utiliser notre application")}</p>
          <div>
            <video
              src={videos}
              width="500"
              height="300"
              controls="controls"
              autoplay="true"
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Tuto;
