import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import { useGetCommandesByUserQuery } from "../../../../redux/features/commandeFeature";
import Layout from "../../../../component/templateMobile/TemplateMobile";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setCommandesLivraisonToDay,
  setCommandesPassed,
} from "../../../../redux/slices/commandeSlice";
import SkeletonComponent from "../../../../utils/Material-ui/skeleton";
import CommandeItemHome from "../../../../component/CommandeItemHome";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Badge } from "@mui/material";
import { useTranslation } from "react-i18next";

const CommandesLivraisonAujourdhui = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState("1"); // Utilisez un état local pour gérer la valeur de l'onglet actif
  const { t } = useTranslation();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const commandesLivraisonAujourdhui = useSelector(
    (state) => state.commandesSlice.commandesLivraisonAujourdhui
  );
  const commandesPasses = useSelector(
    (state) => state.commandesSlice.commandesPasses
  );

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state?.UserSlice?.info);
  const {
    data: datas,
    isLoading,
    isError,
  } = useGetCommandesByUserQuery(currentUser?.id);

  const [commandesPassee, setCommandesPasses] = useState([]);
  const [commandesALivraisonAujourdhui, setCommandesALivraisonAujourdhui] = useState([]);

  useEffect(() => {
    if (datas && datas?.data) {
      const commandes = datas?.data;
      const dateAujourdhui = moment().startOf("day"); // Date d'aujourd'hui à minuit

      // Filtrer les commandes à livrer aujourd'hui
      const commandesLivraisonAujourdhui = commandes.filter((commande) => {
        const dateLivraison = moment(commande.date_livraison);
        if (commande.statut !== "livree" && dateLivraison.isSame(dateAujourdhui, "day")) {
          return true;
        }
        return false;
      });

      // Filtrer les commandes passées (antérieures à aujourd'hui)
      const commandesPasse = commandes.filter((commande) => {
        const dateLivraison = moment(commande.date_livraison);
        if (commande.statut !== "livree" && dateLivraison.isBefore(dateAujourdhui, "day")) {
          return true;
        }
        return false;
      });

      dispatch(setCommandesLivraisonToDay(commandesLivraisonAujourdhui?.length));
      dispatch(setCommandesPassed(commandesPasse?.length));
      setCommandesPasses(commandesPasse);
      setCommandesALivraisonAujourdhui(commandesLivraisonAujourdhui);
    }
  }, [datas]);
console.log('commandesPassee',commandesPassee);
  const leftContent = (
    <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
      <IoIosArrowBack size={24} />
    </div>
  ); 

  const centerContent = (
    <div className="titre-header">
      <span>{t("livraisons des commandes")}</span>
    </div>
  );
  const rightContent = null;

  return (
    <Layout
      leftContent={leftContent}
      centerContent={centerContent}
      rightContent={rightContent}
    >
      <div className="p-3 my-3">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tab
              label={<Badge badgeContent={commandesPasses} color="error">{t("Non livrée")}:</Badge>}
              value="1"
              onClick={(e) => handleChange(e, "1")}
              style={value ==="1" ? {bacgroundColor:"red", color:"red"} : {}}
            />
            <Tab
              label={<Badge badgeContent={commandesLivraisonAujourdhui} color="error">{t("Pour aujourd'hui")}</Badge>}
              value="2"
              onClick={(e) => handleChange(e, "2")}
              style={value === '2' ? {bacgroundColor:"red", color:"red"} : {}}
            />
          </Box>
        </Box>

        {/* Affichage du nombre de commandes à livrer aujourd'hui */}

        {/* Affichage du nombre de commandes passées (antérieures à aujourd'hui) */}
        {value === "1" && (
          <div>
            {isLoading ? (
              <SkeletonComponent />
            ) : isError ? (
              <p
                style={{
                  marginTop: " 100px",
                  color: "red",
                  padding:"30px"
                }}
              >
                Une erreur s'est produite lors du chargement des données.
              </p>
            ) : commandesPassee && commandesPassee?.length > 0 ? (
              commandesPassee.map((commande, index) => (
                <CommandeItemHome
                  key={index}
                  name={commande?.client?.nom_complet}
                  date={moment(commande?.date_livraison).format(
                    "DD/MM/YYYY HH:mm"
                  )}
                  status={commande?.statut}
                  montant={commande?.montant}
                  montant_paye={commande?.montant_paye}
                  montant_restant={commande?.montant_restant}
                />
              ))
            ) : (
              <p
                className=""
                style={{
                  marginTop: " 100px",
                  color: "red",
                  padding:"30px"
                }}
              >
                Pas de données
              </p>
            )}
          </div>
        )}
        {value === "2" && (
          <div>
            {isLoading ? (
              <SkeletonComponent />
            ) : isError ? (
              <p
                style={{
                  marginTop: " 100px",
                  color: "red",
                  padding:"30px"
                }}
              >
                {t("Une erreur s'est produite lors du chargement des données.")}
              </p>
            ) : commandesALivraisonAujourdhui &&
            commandesALivraisonAujourdhui.length > 0 ? (
              commandesALivraisonAujourdhui.map((commande, index) => (
                <CommandeItemHome
                  key={index}
                  name={commande.client.nom_complet}
                  date={moment(commande.date_livraison).format(
                    "DD/MM/YYYY HH:mm"
                  )}
                  status={commande.statut}
                  montant={commande.montant}
                  montant_paye={commande.montant_paye}
                  montant_restant={commande.montant_restant}
                />
              ))
            ) : (
              <p
                className=""
                style={{
                  marginTop: " 100px",
                  color: "red",
                  padding:"30px"  
                }}
              >
                {t("Pas de données")}
              </p>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default CommandesLivraisonAujourdhui;
