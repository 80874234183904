import React from 'react';
import Layout from '../../component/templateMobile/TemplateMobile';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Badge } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { pourfemme } from '../../assets/index';
import { IoIosArrowBack } from 'react-icons/io';
import { useDispatch } from 'react-redux'; // Importer useDispatch
import { useGetGenreByIdQuery } from '../../redux/features/genresFeature';
import { CLIENT_FEMMES, NOTIFICATION_PATH } from '../../routes/path/navigation_path';
import { useGetModelMesureQuery } from '../../redux/features/modelMesureFeature';
import { setSelectedModel } from '../../redux/slices/modelSlice';
import "./mesures.css"

const MesuresFemmes = () => {
  const navigate = useNavigate();
  const { genreId } = useParams();
  const { data, error, isLoading } = useGetGenreByIdQuery(genreId);
  const { data: modelData } = useGetModelMesureQuery(genreId);


  const dispatch = useDispatch();

  const leftContent = (
    <Link>
      <div style={{ cursor: 'pointer', color: 'black' }}>
        <IoIosArrowBack size={20} onClick={() => navigate(-1)} />
      </div>
    </Link>
  );

  const centerContent = (
    <div className="titre-header">
      <span>Les modeles</span>
    </div>
  );

  const rightContent = (
    <div>
      <Badge color="success">
        <NotificationsNoneIcon
          style={{ fontSize: '25px', color: '#8E8EA1' }}
          onClick={() => navigate()}
        />
      </Badge>
    </div>
  );

  const handleModelClick = (model) => {
    dispatch(setSelectedModel(model));
  };

  return (
    <Layout leftContent={leftContent} centerContent={centerContent} rightContent={rightContent}>
      <div className="image-container">
        {modelData?.map((model) => (
          <div key={model.id} className="image-wrapper">
            {/* Utiliser onClick pour appeler handleModelClick */}
            <Link to={`${CLIENT_FEMMES}/${model.id}`} onClick={() => handleModelClick(model)}>
              <img src={model.image} alt={model.nom} onError={(e) => { e.target.src = pourfemme }} />
              {/* <div>{model.nom}</div> */}
            </Link>
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default MesuresFemmes;
