import React from 'react';
import Fab from '@mui/material/Fab';
import { BsPlus } from 'react-icons/bs';

const ButtonAjout = ({ onClick }) => {
  return (
    <div style={{ position: 'fixed', bottom: '65px', left: '50%', transform: 'translateX(-50%)' }}>
    <Fab size="30px" aria-label="add" color="dark" style={{ backgroundColor: '#E72D32' }}>
      <BsPlus size={34} color="white" onClick={onClick} />
    </Fab>
  </div>
  );
};

export default ButtonAjout;
