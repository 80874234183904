import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./css/profilTayeur.css";
import "../utils/GlobalStyle.css";
import Header from "./templateMobile/Header";
import {
  ACCUEIL_PATH,
  INFOSATELIER_PATH,
  LOGIN_PATH,
  MODIF_PARAMETRE_PATH,
  PERSONALISER_PATH,
  TUTORIEL_PATH,
} from "../routes/path/navigation_path";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { clearUser } from "../redux/slices/UserSlice";
import Layout from "./templateMobile/TemplateMobile";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import AirplayIcon from '@mui/icons-material/Airplay';
import {
  useGetParametreByUserQuery,
  useGetParametreQuery,
  useUpdateParametreMutation,
} from "../redux/features/paramettreFeature";
import SettingsIcon from "@mui/icons-material/Settings";
import InfoIcon from "@mui/icons-material/Info";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import {
  useGetUserByIdQuery,
  useUpdateUserMutation,
} from "../redux/features/userFeature";
import { ToastContainer, toast } from "react-toastify";
import ButtonSizes from "../utils/Material-ui/validerButton";
import ButtonAnnuler from "../utils/Material-ui/annulerButton";
import {
  ressetCurrentParametres,
  setParametresList,
  updateParametresInList,
} from "../redux/slices/parametreSlice";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { DEFAULT_API_IMAGE } from "../routes/api_routes";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import LanguageSelector from "./Langage/LanguageSelector";
import { useTranslation } from "react-i18next";
// import SendNotification from "./Notif/SendNotification"


const ProfilClient = () => {
  const currentUser = useSelector((state) => state?.UserSlice?.info);
  const [isFileInputVisible, setIsFileInputVisible] = useState(false);

  const { t } = useTranslation();
  const [updateUserMutation] = useUpdateUserMutation(currentUser?.id || "");

  const dispatch = useDispatch();
  const {
    data: paramettre,
    Loading,
    isError,
    // refetch,
  } = useGetParametreByUserQuery(currentUser?.id);
  // console.log({paramettre});
  const id = paramettre && paramettre.length > 0 ? paramettre[0]?.id : null;
  const { data: getParamettre, loading } = useGetParametreQuery(id);
  // console.log('image',id);
  const [updateParametre] = useUpdateParametreMutation();
  const {
    data: user,
    isLoading,
    error,
    refetch,
  } = useGetUserByIdQuery(currentUser?.id);

  const navigate = useNavigate();

  // const [parsedInfoClient, setParsedInfoClient] = useState(null);
  // const [isLoading, setIsLoading] = useState(false);

  //deconnection
  const disconnect = () => {
    dispatch(clearUser());
    navigate(LOGIN_PATH);
  };

  const leftContent = (
    <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
      <IoIosArrowBack size={24} />
    </div>
  );

  const centerContent = (
    <div className="titre-header">
      <span>{t("Paramètres")}</span>
    </div>
  );

  const rightContent = (
    <div>
       <LanguageSelector />
    </div>
  );
  // edit photo
  const [photo, setPhotos] = useState(false);
  const [isEditingPhoto, setIsEditingPhoto] = useState(false);

  const [editedPhoto, setEditedPhoto] = useState({});
  // edit profil
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingPhone, setIsEditingPhone] = useState(false);

  const startEditing = (field) => {
    setIsEditing(true);
    setEditedData({ ...user });

    // Activez l'édition pour le champ spécifique
    if (field === "lastName" || field === "fastName") {
      setIsEditing(true);
      setIsEditingPhone(false); // Assurez-vous que le champ phone est désactivé
    } else if (field === "phone") {
      setIsEditingPhone(true);
      setIsEditing(false); // Assurez-vous que le champ name est désactivé
    }
  };

  const cancelEditing = () => {
    setIsEditing(false);
    setIsEditingName(false);
    setIsEditingPhone(false);
    setEditedData({});
  };
// console.log(currentUser.id);
  const saveChanges = async () => {
    try {
      const res = await updateUserMutation({ id: currentUser?.id, body: editedData });

      // console.log(res);

      if (res && res?.data) {
        // Si la réponse est conforme à vos attentes
        console.log("Profile modifié avec succès !", res);
        setIsEditing(false);
        setIsEditingName(false);
        setIsEditingPhone(false);
        setEditedData({});

        dispatch(updateParametresInList(res?.data));
        refetch(); // Utilisez refetch pour mettre à jour les données après la mutation
        toast.success("Profil mis à jour avec succès !");
      } else {
        // Si la réponse n'est pas conforme à vos attentes
        console.error("La réponse de l'API n'est pas conforme :", res.data);
        toast.error(
          "Erreur lors de la mise à jour des mesures. Veuillez vérifier la console pour plus de détails."
        );
      }
    } catch (error) {
      // Gérez les erreurs liées à la mutation
      console.error("Erreur lors de la mise à jour de l'utilisateur", error);
    }
  };
  const handleEditPhoto = () => {
    setIsEditingPhoto(true);
  };
  // save photo ptofil
  // const updateParametre = useUpdateParametreMutation();

  
  const saveProfilPhoto = async () => {
    try {
      if (!id || !editedPhoto) {
        console.error("Données de fichier incorrectes. Impossible d'envoyer la requête PUT.", editedPhoto);
        return;
      }
  
      const formData = new FormData();
      formData.append("profile_image", editedPhoto?.newPhoto);
  console.log({editedPhoto});
  console.log({formData});
      const res = await updateParametre({
        id,
        body: formData
      });
  
      console.log("API response:", res);
  
      // Reste du code...
    } catch (error) {
      // Gérez les erreurs liées à la mutation
      console.error("Erreur lors de la mise à jour de la photo", error);
    } finally {
      setIsEditingPhoto(false);
    }
  };
  
  
  
  
  const [videoPlayed, setVideoPlayed] = useState(false);

  useEffect(() => {
    // Si la vidéo n'a pas encore été jouée, la lire
    if (!videoPlayed) {
      // Marquer la vidéo comme jouée
      setVideoPlayed(true);

      // Optionnel : Ajouter un événement pour réinitialiser le state si l'utilisateur recharge la page
      window.addEventListener('beforeunload', () => {
        setVideoPlayed(false);
      });
    }
  }, [videoPlayed]); 
  

  // kkkkkkkkkkkkkkkkkkkkkkkkkkk
  const handleFileChange = (event) => {
    const newPhoto = event.target.files[0];
    setEditedPhoto({newPhoto });
  };
  const renderProfilePhoto = () => {
    if (isEditingPhoto) {
      // Interface d'édition de la photo
      return (
        <div>
          <input
            type="file"
            onChange={handleFileChange}
          />
          <ButtonSizes onClick={saveProfilPhoto} className="mb-2" />
          <ButtonAnnuler onClick={cancelEditing} className="mb-2" />
        </div>
      );
    } else {
      // Affichage de la photo actuelle
      const profileImage = getParamettre?.length > 0 ? getParamettre?.profile_image : null;

      return profileImage ? (
        <img
          src={DEFAULT_API_IMAGE + profileImage}
          alt="Photo de profil"
          className="profile-img"
          style={{ width: 80, height: 80, margin: 'auto' }}
        />
      ) : (
        // Afficher une image par défaut ou un espace réservé si getParamettre n'est pas défini ou vide
        // Ajoutez le code approprié ici pour afficher une image par défaut
        null
      );
    }
  };
  // const handleFieldCancel = () => {
  //   setIsEditing(false);
  //   setIsNomAtelier(false);
  //   setIsPays(false);
  //   setIsVille(false);
  //   setIsNumero(false);
  //   setEditedData({});
  // };

  useEffect(() => {}, [paramettre, editedData, getParamettre]);

  return (
    <Layout
      leftContent={leftContent}
      centerContent={centerContent}
      rightContent={rightContent}
    >
      <div className="container">
        <ToastContainer />
        <div style={{ marginTop: "80px" }}>
        <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "100px",
                  position: "relative",
                }}
              >
                <img
                  src={DEFAULT_API_IMAGE + `${getParamettre?.profile_image}`}
                  alt="imageAlbum"
                  style={{
                    width: 100,
                    height: 100,
                    margin: "auto",
                    borderRadius: "50%",
                    objectFit: "fill",
                  }}
                  onError={(e) =>
                    console.log("Erreur de chargement de l'image", e)
                  }
                />
                {/* Icône pour changer l'image */}
                <div
                  style={{
                    marginTop: "-30px",
                    marginLeft: "50px",
                    cursor: "pointer",
                    color: "#E82828",
                  }}
                  onClick={() => {
                    // Affichez l'input de type "file"
                    setIsFileInputVisible(true);
                  }}
                >
                  <AddAPhotoOutlinedIcon />
                </div>
                {/* Input de type "file" caché */}
                {isFileInputVisible && (
                  <>
                    <input
                      id="fileInput"
                      type="file"
                      style={{ display: "none" }}
                      onChange={async (e) => {
                        try {
                          const newPhoto = e.target.files[0];
                          console.log(
                            "Changer l'image avec le fichier :",
                            newPhoto
                          );

                          const formData = new FormData();
                          formData.append("profile_image", newPhoto);
                          const res = await updateParametre({
                            id,
                            body: formData,
                          });

                          if (res && res?.data) {
                            // Si la réponse est conforme à vos attentes
                            console.log(
                              "Photo de profil modifiée avec succès !",
                              res
                            );
                            setIsFileInputVisible(false);
                            dispatch(updateParametresInList(res?.data));
                            refetch();
                            toast.success(
                              "Photo de profil mise à jour avec succès !"
                            );
                          } else {
                            // Si la réponse n'est pas conforme à vos attentes
                            console.error(
                              "La réponse de l'API n'est pas conforme :",
                              res
                            );
                            toast.error(
                              "Erreur lors de la mise à jour de la photo de profil."
                            );
                          }
                        } catch (error) {
                          // Gérez les erreurs liées à la mutation
                          console.error(
                            "Erreur lors de la mise à jour de la photo de profil",
                            error
                          );
                          toast.error(
                            "Erreur lors de la mise à jour de la photo de profil."
                          );
                        }
                      }}
                    />

                    {/* Label stylisé pour simuler un clic sur l'input de type "file" */}
                    <label
                      htmlFor="fileInput"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                      }}
                    ></label>
                  </>
                )}
              </div>
{/* <IconButton onClick={handleEditPhoto}>
  <EditIcon />
</IconButton> */}


          <div className="">
            <span>{t("profile")} </span>
          </div>
          <div className="profiles">
            <div className="params">
              <div style={{ display: "flex", alignItems: "center" }}>
                <PersonOutlineIcon />
                {isEditing ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="text"
                      value={`${editedData.firstName || ""} ${
                        editedData.lastName || ""
                      }`}
                      onChange={(e) => {
                        const [firstName, lastName] = e.target.value.split(" ");
                        setEditedData({
                          firstName: firstName || "",
                          lastName: lastName || "",
                        });
                      }}
                    />
                    <div className="d-flex flex-row">
                      <ButtonSizes
                        onClick={() => saveChanges("lastName")}
                        className="mb-2"
                      />
                      <ButtonAnnuler onClick={cancelEditing} className="mb-2" />
                    </div>
                  </div>
                ) : (
                  <React.Fragment>
                    <span className="text mx-3">
                      {user?.firstName} {user?.lastName}
                    </span>
                    <BorderColorOutlinedIcon
                      onClick={startEditing}
                      style={{ marginLeft: "auto", color: "#E82828" }}
                    />
                  </React.Fragment>
                )}
              </div>
            </div>
                {/* <SendNotification /> */}
            <div className="params">
              <div style={{ display: "flex", alignItems: "center" }}>
                <PhoneIcon />
                {isEditingPhone ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="text"
                      value={editedData.phone}
                      onChange={(e) =>
                        setEditedData({ ...editedData, phone: e.target.value })
                      }
                    />
                    <div>
                      <div className="d-flex flex-row">
                        <ButtonSizes
                          onClick={() => saveChanges("phone")}
                          className="mb-2"
                        />
                        <ButtonAnnuler
                          onClick={cancelEditing}
                          className="mb-2"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <React.Fragment>
                    <span className="text mx-3">{user?.phone}</span>
                    {/* <BorderColorOutlinedIcon
                      onClick={() => startEditing("phone")}
                      style={{ marginLeft: "auto", color: "#E82828" }}
                    /> */}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* mon atelier */}
        <div style={{ marginTop: "20px" }}>
          <div className="">
            <span>{t("informations")}</span>
          </div>
          <div className="profiles">
            <div className="iconText">
              <InfoIcon />
              <span
                className="text mx-3"
                onClick={() => navigate(INFOSATELIER_PATH)}
              >
                {t("Les informations sur mon atelier")}
              </span>
            </div>
            </div>
            {/* <div className="profiles">
            <div className="iconText">
             
              <SettingsIcon />
              {paramettre && !paramettre[0]?.mesure_title ? (
                <span
                  className="text mx-3"
                  onClick={() => navigate(PERSONALISER_PATH)}
                >
                  {t("Modification des paramétres de mesures")}
                </span>
              ) : (
                <span
                  className="text mx-3"
                  onClick={() =>
                    navigate(`/modif-parametre-atelier/${getParamettre?.id}`)
                  }
                  // to={`/detail-commande/${item?.id}`}
                >
                   {t("Modification des paramétres de mesures")}
                </span>
              )}
              
            </div>
          </div> */}
          {/* <div className="profiles">
          <div className="iconText">
             
             <AirplayIcon />
        
               <span
                 className="text mx-3"
                 onClick={() => navigate(TUTORIEL_PATH)}
               >
                 {t("Tutoriel")}
               </span>
           
              
           
             
           </div>
           </div> */}
        </div>

        {/* deconnexion */}
        <div style={{ marginTop: "20px", color: "red" }}>
          {/* <div className="" >
                    <span>Mon Atelier</span>
                  </div> */}
          <div className="profiles mt-5" onClick={() => disconnect()}>
            <div className="iconText">
              <LogoutIcon />
              <span className="text mx-3" style={{ color: "red" }}>
                {t("Se déconnecter")} ({user?.phone})
              </span>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
    </Layout>
  );
};

export default ProfilClient;
