import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import frenchFlag from "./french-Flag.png";
import arabicFlag from './arabe-flag.png'; 
import './LanguageSelector.css'; 

const languages = [
    // { code: "en", lang: "English" },
    { code: "fr", lang: "Français", flag: frenchFlag }, // Ajouter l'image du drapeau français
    { code: "ar", lang: "Arabe", flag: arabicFlag }, // Ajouter l'image du drapeau arabe
];

const LanguageSelector = () => {
    const { i18n } = useTranslation();

    const changeLanguage = (event) => {
        const selectedLanguageCode = event.target.value;
        if (i18n.language !== selectedLanguageCode) {
            i18n.changeLanguage(selectedLanguageCode);
        }
    };

    useEffect(() => {
      document.body.dir = i18n.dir();
    }, [i18n,i18n.language])

    return (
        <div className="custom-select-wrapper">
        <select
            value={i18n.language}
            onChange={changeLanguage}
            className="custom-select"
        >
            {languages.map((lng) => (
                <option
                    key={lng.code}
                    value={lng.code}
                >
                    <img src={lng.flag} alt={lng.lang} className="flag-icon" />
                    {lng.lang}
                </option>
            ))}
        </select>
    </div>
    );
};

export default LanguageSelector;
