import { createSlice } from "@reduxjs/toolkit";

const useSlice = createSlice({
  name: "user",
  initialState: {
    info: null,
    isAuthenticated: false,
  },
  reducers: {
    setUser: (state, { payload }) => {
      state.info = payload;
      state.isAuthenticated = true;
    },
    clearUser: (state) => {
      state.info = null;
      state.csrftoken = null;
      state.isAuthenticated = false;
    },
  },
});

export const { setUser, clearUser } = useSlice.actions;
export default useSlice.reducer;
