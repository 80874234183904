import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ACCUEIL_PATH, REGISTER_PATH } from "../../routes/path/navigation_path";
import "./auth.css";
import { useForm } from "react-hook-form";
import { useLoginMutation } from "../../redux/features/userFeature";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/slices/UserSlice";
import { ToastContainer, toast } from "react-toastify";
import logo from "../../assets/img/tayeur-logo1.png";
import mixpanel from "mixpanel-browser";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const Login = () => {
  const [login] = useLoginMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); 

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  const handleLogin = async (data) => {
    setIsLoading(true);
    try {
      const phone = data.phone;
      const password = data.password;
      const body = JSON.stringify({ phone, password });
      const response = await login(body);
      if (response?.error) {
        if (response?.error?.status === 401) {
          setError("password", {
            type: "manual",
            message: "Numéro de téléphone ou mot de passe incorrect",
          });
        }else {
          alert("erreur lors de l'authentification")
        }
      } else {
        dispatch(setUser(response?.data));
        toast.success("Vous êtes connecté avec succès !", {
          position: toast.POSITION.TOP_RIGHT,
        });

        mixpanel.track("Login", { "Login Type": "Standard" });
        setTimeout(() => {
          navigate(ACCUEIL_PATH);
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  return (
    <div
      className="container-fluid"
      style={{ maxWidth: "500px", margin: "auto", marginTop: "50px" }}
    >
      <div className="row">
        <div className="col-lg-12 col-md-12 form-container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-box text-center">
            <div className="logo mb-3">
              <img src={logo} alt="Image d'illustration" />
            </div>
            <form onSubmit={handleSubmit(handleLogin)}>
              <div className="form-input">
                <span>
                  <i className="fa fa-envelope"></i>
                </span>
                <input
                  style={{ backgroundColor: "#D9D9D9" }}
                  {...register("phone", {
                    required: "Ce champ est obligatoire!",
                  })}
                  type="tel"
                  placeholder="Téléphone"
                  id="phone"
                  name="phone"
                />
              </div>
              {errors.phone && (
                <p className="text-danger mb-4">{errors?.phone?.message}</p>
              )}
              <div className="form-input">
                <span>
                  <i className="fa fa-lock"></i>
                </span>
                <div style={{ position: "relative" }}>
                  <input
                    style={{ backgroundColor: "#D9D9D9" }}
                    {...register("password", {
                      required: "Ce champ est obligatoire!",
                    })}
                    type={showPassword ? "text" : "password"} 
                    placeholder="********"
                    id="password"
                    name="password"
                  />
                  <span
                    className="eye-icon"
                    style={{ position: "absolute", top: "35%", right: "5px", transform: "translateY(-50%)" }} 
                    onClick={() => setShowPassword(!showPassword)} // Inversez l'état showPassword lorsqu'on clique sur l'icône
                  >
                    <RemoveRedEyeIcon />
                  </span>
                </div>
              </div>
              {errors.password && (
                <p className="text-danger mb-4">{errors?.password?.message}</p>
              )}

              <div className="text-left mb-3">
                <button type="submit" className="btn" style={{ padding: "13px" }}>
                  {isLoading ? (
                    <div className="spinner-border text-light" role="status"></div>
                  ) : (
                    "Connexion"
                  )}
                </button>
              </div>
              <Link style={{ color: "black" }} to={REGISTER_PATH}>
                Inscrivez-vous
              </Link>
            </form>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 d-none d-md-block image-container"></div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Login;

