import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./css/profilTayeur.css";
import "../utils/GlobalStyle.css";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import Layout from "./templateMobile/TemplateMobile";
import {
  useGetParametreByUserQuery,
  useGetParametreQuery,
  useUpdateParametreMutation,
} from "../redux/features/paramettreFeature";
import SkeletonComponent from "../utils/Material-ui/skeleton";
import { DEFAULT_API_IMAGE } from "../routes/api_routes";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { ToastContainer, toast } from "react-toastify";
import ButtonSizes from "../utils/Material-ui/validerButton";
import ButtonAnnuler from "../utils/Material-ui/annulerButton";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import { updateParametresInList } from "../redux/slices/parametreSlice";

const InfoAtelier = () => {
  const currentUser = useSelector((state) => state?.UserSlice?.info);
  const [isFileInputVisible, setIsFileInputVisible] = useState(false);

  const dispatch = useDispatch();
  const [updateParametre] = useUpdateParametreMutation();
  const {
    data: paramettre,
    Loading,
    isError,
    refetch,
  } = useGetParametreByUserQuery(currentUser?.id);

  // console.log("userData", paramettre);
  const id = paramettre[0]?.id;
  const { data: getParamettre, isLoading, error } = useGetParametreQuery(id);
  console.log({ getParamettre });

  useEffect(() => {
    refetch();
  }, [paramettre, getParamettre]);

  console.log({ getParamettre });
  const navigate = useNavigate();

  const leftContent = (
    <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
      <IoIosArrowBack size={24} />
    </div>
  );

  const centerContent = (
    <div className="titre-header">
      <span>Information atelier</span>
    </div>
  );

  const rightContent = null;

  // modificcation
  const [isNomAtelier, setIsNomAtelier] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isNumero, setIsNumero] = useState(false);
  const [isVille, setIsVille] = useState(false);
  const [isPays, setIsPays] = useState(false);
  const [editedData, setEditedData] = useState({});

  const handleFieldClick = (field) => {
    setIsEditing(true);
    setEditedData({ [field]: paramettre[0]?.[field] || "" });
  };

  const handleFieldChange = (field, value) => {
    setEditedData({ ...editedData, [field]: value });
  };

  const handleFieldSave = async () => {
    try {
      const res = await updateParametre({ id, body: editedData });
      console.log(res);

      if (res && res?.data) {
        // Si la réponse est conforme à vos attentes
        console.log("Profile modifié avec succès !", res);
        setIsEditing(false);
        setIsNomAtelier(false);
        setIsPays(false);
        setIsVille(false);
        setIsNumero(false);

        dispatch(updateParametresInList(res?.data));
        refetch();
        toast.success("Profil mis à jour avec succès !");
      } else {
        // Si la réponse n'est pas conforme à vos attentes
        console.error("La réponse de l'API n'est pas conforme :", res);
        toast.error("Erreur lors de la mise à jour des mesures.");
      }
    } catch (error) {
      // Gérez les erreurs liées à la mutation
      console.error("Erreur lors de la mise à jour de l'utilisateur", error);
    }
  };

  const handleFieldCancel = () => {
    setIsEditing(false);
    setIsNomAtelier(false);
    setIsPays(false);
    setIsVille(false);
    setIsNumero(false);
    setEditedData({});
  };

  return (
    <Layout
      leftContent={leftContent}
      centerContent={centerContent}
      rightContent={rightContent}
    >
      <div className="container">
        <ToastContainer />
        {!isLoading && !isError && getParamettre ? (
          <div>
            <div className="">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "100px",
                  position: "relative",
                }}
              >
                <img
                  src={DEFAULT_API_IMAGE + `${getParamettre?.profile_image}`}
                  alt="imageAlbum"
                  style={{
                    width: 100,
                    height: 100,
                    margin: "auto",
                    borderRadius: "50%",
                    objectFit: "fill",
                  }}
                  onError={(e) =>
                    console.log("Erreur de chargement de l'image", e)
                  }
                />
                {/* Icône pour changer l'image */}
                <div
                  style={{
                    marginTop: "-30px",
                    marginLeft: "50px",
                    cursor: "pointer",
                    color: "#E82828",
                  }}
                  onClick={() => {
                    // Affichez l'input de type "file"
                    setIsFileInputVisible(true);
                  }}
                >
                  <AddAPhotoOutlinedIcon />
                </div>
                {/* Input de type "file" caché */}
                {isFileInputVisible && (
                  <>
                    <input
                      id="fileInput"
                      type="file"
                      style={{ display: "none" }}
                      onChange={async (e) => {
                        try {
                          const newPhoto = e.target.files[0];
                          console.log(
                            "Changer l'image avec le fichier :",
                            newPhoto
                          );

                          const formData = new FormData();
                          formData.append("profile_image", newPhoto);

                          const res = await updateParametre({
                            id,
                            body: formData,
                          });

                          if (res && res?.data) {
                            // Si la réponse est conforme à vos attentes
                            console.log(
                              "Photo de profil modifiée avec succès !",
                              res
                            );
                            setIsFileInputVisible(false);
                            dispatch(updateParametresInList(res?.data));
                            refetch();
                            toast.success(
                              "Photo de profil mise à jour avec succès !"
                            );
                          } else {
                            // Si la réponse n'est pas conforme à vos attentes
                            console.error(
                              "La réponse de l'API n'est pas conforme :",
                              res
                            );
                            toast.error(
                              "Erreur lors de la mise à jour de la photo de profil."
                            );
                          }
                        } catch (error) {
                          // Gérez les erreurs liées à la mutation
                          console.error(
                            "Erreur lors de la mise à jour de la photo de profil",
                            error
                          );
                          toast.error(
                            "Erreur lors de la mise à jour de la photo de profil."
                          );
                        }
                      }}
                    />

                    {/* Label stylisé pour simuler un clic sur l'input de type "file" */}
                    <label
                      htmlFor="fileInput"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                      }}
                    ></label>
                  </>
                )}
              </div>

              <div className="params">
                <span
                  className="text mx-3"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <div>Nom atelier : </div>
                  {isNomAtelier ? (
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      className="flex-wrap mt-2"
                    >
                      <input
                        type="text"
                        value={editedData?.nom_attelier || ""}
                        onChange={(e) =>
                          handleFieldChange("nom_attelier", e.target.value)
                        }
                      />

                      <div className="d-flex flex-row">
                        <ButtonSizes
                          onClick={() => handleFieldSave("nom_attelier")}
                          className="mb-2"
                        />
                        <ButtonAnnuler
                          onClick={handleFieldCancel}
                          className="mb-2"
                        />
                      </div>
                    </div>
                  ) : (
                    <React.Fragment>
                      <span
                        onClick={() => handleFieldClick("nom_attelier")}
                        className="mx-2"
                      >
                        {getParamettre?.nom_attelier || "pas défini"}
                      </span>
                      <BorderColorOutlinedIcon
                        style={{ marginLeft: "auto", color: "#E82828" }}
                        onClick={() => {
                          // setIsEditing(true);
                          setEditedData({
                            nom_attelier: getParamettre?.nom_attelier || "",
                          });
                          setIsNomAtelier(true);
                        }}
                      />
                    </React.Fragment>
                  )}
                </span>
              </div>

              <div className="params">
                <span
                  className="text mx-3"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div>Numéro WhatsApp: </div>
                  {isNumero ? (
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      className="flex-wrap mt-2"
                    >
                      <input
                        type="text"
                        value={editedData?.numWhtsapp || ""}
                        onChange={(e) =>
                          handleFieldChange("numWhtsapp", e.target.value)
                        }
                      />
                      <div className="d-flex flex-row">
                        <ButtonSizes
                          onClick={() => handleFieldSave("numWhtsapp")}
                          className="mb-2"
                        />
                        <ButtonAnnuler
                          onClick={handleFieldCancel}
                          className="mb-2"
                        />
                      </div>
                    </div>
                  ) : (
                    <React.Fragment>
                      <span
                        onClick={() => handleFieldClick("numWhtsapp")}
                        className="mx-2"
                      >
                        {getParamettre?.numWhtsapp || "pas défini"}
                      </span>
                      <BorderColorOutlinedIcon
                        style={{ marginLeft: "auto", color: "#E82828" }}
                        onClick={() => {
                          // setIsEditing(true);
                          setEditedData({
                            numWhtsapp: getParamettre?.numWhtsapp || "",
                          });
                          setIsNumero(true);
                        }}
                      />
                    </React.Fragment>
                  )}
                </span>
              </div>

              <div className="params">
                <span
                  className="text mx-3"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Ville :{" "}
                  {isVille ? (
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      className="flex-wrap mt-2"
                    >
                      <input
                        type="text"
                        value={editedData?.ville || ""}
                        onChange={(e) =>
                          handleFieldChange("ville", e.target.value)
                        }
                      />
                      <div className="d-flex flex-row">
                        <ButtonSizes
                          onClick={() => handleFieldSave("ville")}
                          className="mb-2"
                        />
                        {/* <button onClick={() => handleFieldSave("ville")}>
                          Enregistrer
                        </button> */}
                        <ButtonAnnuler
                          onClick={handleFieldCancel}
                          className="mb-2"
                        />
                      </div>
                    </div>
                  ) : (
                    <React.Fragment>
                      <span
                        onClick={() => handleFieldClick("ville")}
                        className="mx-2"
                      >
                        {getParamettre?.ville || "pas défini"}
                      </span>
                      <BorderColorOutlinedIcon
                        style={{ marginLeft: "auto", color: "#E82828" }}
                        onClick={() => {
                          // setIsEditing(true);
                          setEditedData({
                            ville: getParamettre?.ville || "",
                          });
                          setIsVille(true);
                        }}
                      />
                    </React.Fragment>
                  )}
                </span>
              </div>

              <div className="params">
                <span
                  className="text mx-3"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Pays :{" "}
                  {isPays ? (
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      className="flex-wrap mt-2"
                    >
                      <input
                        type="text"
                        value={editedData?.pays || ""}
                        onChange={(e) =>
                          handleFieldChange("pays", e.target.value)
                        }
                      />
                      <div className="d-flex flex-row">
                        <ButtonSizes
                          onClick={() => handleFieldSave("pays")}
                          className="mb-2"
                        />
                        <ButtonAnnuler
                          onClick={handleFieldCancel}
                          className="mb-2"
                        />
                      </div>
                    </div>
                  ) : (
                    <React.Fragment>
                      <span
                        onClick={() => handleFieldClick("pays")}
                        className="mx-2"
                      >
                        {getParamettre?.pays || "pas défini"}
                      </span>
                      <BorderColorOutlinedIcon
                        style={{ marginLeft: "auto", color: "#E82828" }}
                        onClick={() => {
                          // setIsEditing(true);
                          setEditedData({
                            pays: getParamettre?.pays || "",
                          });
                          setIsPays(true);
                        }}
                      />
                    </React.Fragment>
                  )}
                </span>
              </div>
            </div>
        
      
            
          </div>
        ) : (
          <p
            className=""
            style={{
              marginTop: " 20%",
              color: " red",
            }}
          >
            Pas de données disponible
          </p>
        )}
      </div>
      <br></br>
      <br></br>
      <br></br>
    </Layout>
  );
};

export default InfoAtelier;
