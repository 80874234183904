// genreSlice.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GENRE_CLIENT_API_ROUTE } from '../../routes/api_routes';
import { ApiMananger } from '../apiManager';

export const genreApi = ApiMananger.injectEndpoints ({
  baseQuery: fetchBaseQuery({ baseUrl: GENRE_CLIENT_API_ROUTE }),
  tagTypes: ['Genres'],
  keepUnusedDataFor: 30,
  endpoints: (builder) => ({
    getGenres: builder.query({
      query: () => 'genres/',
      providesTags: ['Genres'],
    }),
    getGenreById: builder.query({
      query: (genreId) => `/genres/${genreId}`,
      keepUnusedDataFor: 5
    }),
  }),
});

export const { useGetGenresQuery, useGetGenreByIdQuery } = genreApi;

