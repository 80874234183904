import React, { useEffect, useState } from "react";
import "./css/commande.css";
import "../../utils/GlobalStyle.css";
import CommandeItemHome from "../../component/CommandeItemHome";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { ADD_CLIENT_PATH, NOTIFICATION_PATH, PROFIL_PATH } from "../../routes/path/navigation_path";
import moment from "moment";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Badge } from "@mui/material";
import SearchAppBar from "../../utils/Material-ui/SearchInput";
import Layout from "../../component/templateMobile/TemplateMobile";
import SkeletonComponent from "../../utils/Material-ui/skeleton";
import { useTranslation } from "react-i18next";
import { useGetCommandesByUserQuery } from "../../redux/features/commandeFeature";

const Commandes = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.UserSlice.info);
  const dispatch = useDispatch();
  const [filteredCommandes, setFilteredCommandes] = useState([]);
  const { data: datas, isLoading, isError } = useGetCommandesByUserQuery(currentUser?.id);
  const [searchTerm, setSearchTerm] = useState("");

  const sortCommandesByDate = (commandes) => {
    if (!commandes || !Array.isArray(commandes)) {
      return [];
    }
    return commandes.slice().sort((a, b) => new Date(b.date_commande) - new Date(a.date_commande));  };

  const searchCommandes = (term, selectedStatus = "tout") => {
    let filtered = datas;
    
    if (selectedStatus !== "tout") {
      filtered = filtered?.filter((commande) => commande.statut === selectedStatus);
    }

    if (term) {
      filtered = filtered?.filter((commande) =>
        commande.client?.toString().toLowerCase().includes(term.toLowerCase())
      );
    }

    setFilteredCommandes(sortCommandesByDate(filtered));
  };

  // const handleSearch = (e) => {
  //   const term = e.target.value;
  //   setSearchTerm(term);
  //   searchCommandes(term);
  // };
  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    const filteredClients = datas?.filter(
      (client) =>
        client._client?.nom_complet.toLowerCase().includes(term) ||
      client._client?.telephone.includes(term)
    );

    setFilteredCommandes(filteredClients);
  };

  const handleStatusChange = (e) => {
    const selectedStatus = e.target.value;
    searchCommandes(searchTerm, selectedStatus);
  };

  useEffect(() => {
    if (datas) {
      searchCommandes(searchTerm);
    }
  }, [datas]);

  const firstNameInitial = currentUser?.firstName ? currentUser.firstName.charAt(0) : '';
  const lastNameInitial = currentUser?.lastName ? currentUser.lastName.charAt(0) : '';
  const firstLastName = `${firstNameInitial}${lastNameInitial}` || "";

  const leftContent = (
    <Link to={PROFIL_PATH}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <img
          src={require("../../assets/img/profile2.png")}
          alt="Profile"
          className="profile-img m-0"
          style={{ width: 40, height: 40 }}
        />
      </div>
    </Link>
  );

  const centerContent = (
    <div className="titre-header">
      <span>{t("Commandes")}</span>
    </div>
  );

  const rightContent = (
    <div style={{ fontSize: "25px", color: "#8E8EA1", marginRight: "10px" }}>
      <Badge color="success">
        <NotificationsNoneIcon
          style={{ fontSize: "25px", color: "#8E8EA1" }}
          onClick={() => navigate(NOTIFICATION_PATH)}
        />
      </Badge>
    </div>
  );

  return (
    <Layout leftContent={leftContent} centerContent={centerContent} rightContent={rightContent}>
      <div className="home-screen">
        <div className="commande-list">
          <span className="title-en-tete" style={{ color: "#000" }}>
            {t("Liste des commandes")}
          </span>

          <select className="seletedStatus" style={{ padding: "9px" }}>
            <option value="tout">{t("Tout")}</option>
      
          </select>

          <SearchAppBar onSearch={handleSearch} searchTerm={searchTerm} />

          {isLoading ? (
            <SkeletonComponent />
          ) : isError ? (
            <p style={{ marginTop: "100px", color: "red", padding: "30px" }}>
              Une erreur s'est produite lors du chargement des données.
            </p>
          ) : filteredCommandes && filteredCommandes.length > 0 ? (
            filteredCommandes.map((item, index) => (
              <Link
                key={index}
                to={`/detail-commande/${item.id}`}
                style={{ textDecoration: "none" }}
              >
                <CommandeItemHome
                  id={item.id}
                  client={item._client?.nom_complet}
                  model={item._model?.nom}
                  image={item._model?.image}
                  date_commande={moment(item.date_commande).format("DD/MM/YYYY HH:mm")}
                />
              </Link>
            ))
          ) : (
            <p style={{ marginTop: "100px", color: "red", padding: "30px" }}>
              {t("Pas de commandes disponibles")}
            </p>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Commandes;
