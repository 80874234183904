import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  selectedModel: null, 
};


const modelSlice = createSlice({
  name: 'modeles',
  initialState, 
  reducers: {
    setSelectedModel: (state, { payload }) => {
      state.selectedModel = payload
    },
   
    
  },
});

// Export actions and reducer
export const { setSelectedModel } = modelSlice.actions;
export default modelSlice.reducer;
