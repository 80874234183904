import React, { useState } from "react";
import { FiPlus } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { COMMANDES_PATH } from "../../routes/path/navigation_path";
import Layout from "../../component/templateMobile/TemplateMobile";
import { IoIosArrowBack } from "react-icons/io";
import "./css/addCommande.css";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import { useGetClientsQuery } from "../../redux/features/clientFeature";
import AddClientInCommande from "./AddClientInCommande";
import { useTranslation } from "react-i18next";
import AppPadding from "../../component/ui/AppPadding";
import { commandeApi } from "../../redux/features/commandeFeature";

const AddCommandes = () => {
  const navigate = useNavigate();
  const selectedModel = useSelector((state) => state.modelSlice.selectedModel);
  const [showModal, setShowModal] = useState(false);
  const [showClientSelector, setShowClientSelector] = useState(true);
  const currentUser = useSelector((state) => state?.UserSlice?.info);
  const { data } = useGetClientsQuery(currentUser?.id);
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const listClient = useSelector((state) => state.clients?.clientsList);
  const [addedClientId, setAddedClientId] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  // Ajout du numéro de téléphone dans les options
  const options = data?.map((item) => ({
    value: item.id,
    label: item.nom_complet,
    phone: item.telephone,  // Ajout du numéro de téléphone ici
  }));

  const submit = async (formData) => {
    setIsLoading(true);
    try {
      const valeursMesures = selectedModel?.mesures_model.map((mesure) => ({
        mesure: mesure.id,
        valeur: parseFloat(formData[mesure.nom]),
      }));

      const payload = {
        client: {
          nom: selectedClient.label,
          telephone: selectedClient.phone, 
        },
        model_id: selectedModel.id,
        valeurs_mesures: valeursMesures,
      };

      const response = await fetch("https://gestion.tayeur.com/api/v1/ajoutMesuresCommandesV2/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Erreur lors de l'ajout de la commande.");
      }

      const data = await response.json();
      toast.success("Commande ajoutée avec succès !");
      dispatch(commandeApi.util.invalidateTags(["Commande"]))
      navigate(COMMANDES_PATH);
    } catch (error) {
      toast.error(error.message || "Erreur lors de l'ajout de la commande.");
    } finally {
      setIsLoading(false);
    }
  };
  const handleGoBack = () => {
    navigate(-1);
  };
  const openModal = () => {
    setShowModal(true);
    setShowClientSelector(false); // Masquer le sélecteur de client
  };
  const closeModal = () => {
    setShowModal(false);
    setShowClientSelector(true); // Réafficher le sélecteur de client lorsque le modal se ferme
  };
  const leftContent = <IoIosArrowBack size={24} onClick={handleGoBack} />;
  const centerContent = (
    <div className="titre-header">
      <span>{t("Ajouter Commande")}</span>
    </div>
  );
  const rightContent = null;
  const mesuresDuModel = selectedModel?.mesures_model || [];
  return (
    <Layout
      leftContent={leftContent}
      centerContent={centerContent}
      rightContent={rightContent}
    >
      <div className="container">
        <ToastContainer />
        <div className="card-input">
          <form onSubmit={handleSubmit(submit)}>
            <div className="add-commande-container">
              <div className="add-commande-client-section">
                <div className="row section-content displaySelectAndAddClient">
                  <div className="row m-1">
                    <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10">
                      <label htmlFor="clientId" className="labelSmalDevice">
                        {t("Nom client")}
                      </label>
                    </div>
                    <div style={{ textAlign: "center", marginTop: "5px" }}></div>
                    {showClientSelector && (
                      <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10">
                        <Select
                          className="onSmallDevicce"
                          name="clientId"
                          id="clientId"
                          options={options}
                          placeholder={t("Choisissez un client")}
                          isClearable={true}
                          value={selectedClient || null}
                          onChange={(client) => setSelectedClient(client)}
                          required
                        />
                        {errors.clientId && (
                          <p className="error-msg text-center">
                            &nbsp; {errors.clientId.message}
                          </p>
                        )}
                      </div>
                    )}
                    
                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                      <button
                        type="button"
                        className="btn btn-danger mb-2"
                        style={{ width: "40px" }}
                        onClick={openModal}
                      >
                        <FiPlus />
                      </button>
                    </div>
                  </div>
                </div>
                
                <AppPadding>
                {selectedClient && (
                  <div className="client-info-card" style={{background: "white", padding: "10px", borderRadius:"5px"}}>
                    {/* <h5>Informations du client</h5> */}
                    <p style={{display: "flex", justifyContent: "space-between"}}><strong>Nom :</strong> {selectedClient.label}</p>
                    <p style={{display: "flex", justifyContent: "space-between"}}><strong>Téléphone :</strong> {selectedClient.phone}</p>
                  </div>
                )}

                </AppPadding>
              
                <div className="d-flex justify-content-center mb-4">
                  <AddClientInCommande
                    closeModal={closeModal}
                    showModal={showModal}
                    data={data}
                    setAddedClientId={setAddedClientId}
                  />
                </div>
                
                <div className="row section-content mb-4 ajout-client">
                  <div className="col-xl-12 col-lg-12 col-md-10 col-sm-12 col-12">
                    <label className="mesure-du-client" style={{ display: "flex", justifyContent: "center"}}> 
                      <img src={selectedModel?.image} style={{ width: "75px", height: "75px", borderRadius: "50px"}}/> 
                    </label>
                  </div>
                  <div className="row m-1 model">
                    {mesuresDuModel.length > 0 ? (
                      <ul>
                        {mesuresDuModel?.map((mesure, index) => (
                          <li key={index} style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px"}}>
                            <label>{mesure.nom}</label>
                            <input
                              type="number"
                              {...register(mesure.nom, {
                                // required: "Champ obligatoire !",
                              })}
                              placeholder=""
                              style={{
                                color: "#797979",
                                borderRadius: '5px',
                                height: "40px",
                                width: "90px"
                              }}
                            />
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>Aucune mesure disponible pour ce modèle.</p>
                    )}
                  </div>
                  <button
                    className="validateButton mt-2"
                    type="submit"
                    style={{ width: "91%", marginLeft: "16px" }}
                  >
                    {isLoading ? (
                      <div className="spinner-border text-white" role="status"></div>
                    ) : (
                      t("Valider")
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default AddCommandes;
