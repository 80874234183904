// Spinner.js

import React from 'react';
import './Spinner.css'; // Import the CSS for styling

const Spinner = ({ size }) => {
  return (
    <div className={`spinner-container ${size && `spinner-${size}`}`}>
      <div className="spinner"></div>
    </div>
  );
};

export default Spinner;
