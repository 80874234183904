import React, { useState, useEffect } from "react";
import CommandeItemHome from "../../component/CommandeItemHome";
import "./accueil.css";
import { useGetCommandesByUserQuery } from "../../redux/features/commandeFeature";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { PROFIL_PATH } from "../../routes/path/navigation_path";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import "../../utils/GlobalStyle.css";
import { useGetClientsQuery } from "../../redux/features/clientFeature";
import { Badge } from "@mui/material";
import Layout from "../../component/templateMobile/TemplateMobile";
import SkeletonComponent from "../../utils/Material-ui/skeleton";
import {
  setCommandesLivraisonToDay,
  setCommandesPassed,
} from "../../redux/slices/commandeSlice";
import { useTranslation } from "react-i18next";
import femmes from "../../assets/femme.png";
import homme from "../../assets/hommes.png";
import tayeur from "../../assets/tayeur.png";
import AppPadding from "../../component/ui/AppPadding";
import { useGetGenresQuery } from "../../redux/features/genresFeature";
const Accueil = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state?.UserSlice?.info);
  const { t, i18n } = useTranslation();

  console.log(currentUser?.id);
  const {
    data: datas,
    isLoading,
    isError,
  } = useGetCommandesByUserQuery(currentUser?.id, { skip: !currentUser?.id });
  const { data: genresData, isLoading: isLoadingGenres } = useGetGenresQuery();

  console.log("genresData", genresData);

  const { data: dataClient } = useGetClientsQuery(currentUser?.id);

  const { data, error } = useGetClientsQuery(currentUser?.id);

  console.log(dataClient);
  console.log(data);

  const commandesLivraisonAujourdhui = useSelector(
    (state) => state.commandesSlice.commandesLivraisonAujourdhui
  );
  const commandesPasses = useSelector(
    (state) => state.commandesSlice.commandesPasses
  );
  const firstLastName =
    currentUser?.firstName?.charAt(0) + currentUser?.lastName?.charAt(0) || "";
  const leftContent = (
    <Link to={PROFIL_PATH}>
      <div>
        <img
          src={require("../../assets/img/profile2.png")}
          alt="Profile"
          className="profile-img m-0"
          style={{ width: 40, height: 40 }}
        />
      </div>
    </Link>
  );

  const centerContent = (
    <div className="titre-header">
      <span>{t("Accueil")}</span>
    </div>
  );

  const rightContent = (
    <div style={{ fontSize: "25px", color: "#8E8EA1", marginRight: "10px" }}>
      <Badge
        badgeContent={
          (Number(commandesPasses) || 0) +
          (Number(commandesLivraisonAujourdhui) || 0)
        }
        color="success"
      >
        <NotificationsNoneIcon
          style={{ fontSize: "25px", color: "#8E8EA1" }}
          onClick={() => navigate()}
        />
      </Badge>
    </div>
  );

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [commandesPasse, setCommandesPasses] = useState([]);
  const [commandesALivraisonAujourdhui, setCommandesALivraisonAujourdhui] =
    useState([]);
  const [commandesSemaine, setCommandesSemaine] = useState([]);

  useEffect(() => {
    if (datas && datas?.data) {
      const commandes = datas?.data;
      const dateAujourdhui = moment().startOf("day");

      const commandesLivraisonAujourdhuis = commandes.filter((commande) => {
        const dateLivraison = moment(commande.date_livraison);
        if (
          commande.statut !== "livree" &&
          dateLivraison.isSame(dateAujourdhui, "day")
        ) {
          return true;
        }
        return false;
      });

      const commandesPasse = commandes.filter((commande) => {
        const dateLivraison = moment(commande.date_livraison);
        if (
          commande.statut !== "livree" &&
          dateLivraison.isBefore(dateAujourdhui, "day")
        ) {
          return true;
        }
        return false;
      });

      const datePourAujourdhui = moment();
      const debutSemaine = datePourAujourdhui.clone().startOf("isoWeek");
      const finSemaine = datePourAujourdhui.clone().endOf("isoWeek");

      const commandesSemaine = commandes.filter((commande) => {
        const dateLivraison = moment(commande.date_livraison);
        if (
          commande.statut !== "livree" &&
          dateLivraison.isBetween(debutSemaine, finSemaine, undefined, "[)")
        ) {
          return true;
        }

        return false;
      });
      console.log({ commandesSemaine });

      dispatch(
        setCommandesLivraisonToDay(commandesLivraisonAujourdhui?.length)
      );
      dispatch(setCommandesPassed(commandesPasse?.length));
      setCommandesPasses(commandesPasse);
      setCommandesALivraisonAujourdhui(commandesLivraisonAujourdhuis);
      setCommandesSemaine(commandesSemaine);
    }
  }, [datas]);
  return (
    <Layout
      leftContent={leftContent}
      centerContent={centerContent}
      rightContent={rightContent}
    >
      {/* <ButtonAjout onClick={() => navigate(ADD_CLIENT_PATH)} /> */}
      <div>
        <AppPadding>
          <div className="mb-3 rounded-4 m-0 sec1">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src={tayeur} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "10px",
                marginTop: -20,
              }}
            >
              <div style={{ marginRight: "10px", display: "flex" }}>
                {isLoadingGenres ? (
                  <div>Chargement...</div>
                ) : (
                  <ul
                    style={{
                      display: "flex",
                      listStyleType: "none",
                      padding: 0,
                    }}
                  >
                    {genresData?.map((genre) => (
                      <li key={genre.id} style={{ marginRight: "10px" }}>
                        <Link
                          to={`/genres/${genre.id}`}
                          style={{ textAlign: "center" }}
                        >
                          <img
                            src={genre.genre === "Homme" ? homme : femmes}
                            alt={genre.genre}
                            style={{ width: "100px", height: "100px" }}
                          />
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </AppPadding>

        <div className="commande-lists" style={{ fontFamily: "Montserrat" }}>
          <div style={{ margin: "5px", color: "#000" }}>
            <span className="title-en-tete">{t("Listes des commandes")}</span>
          </div>
          {isLoading ? (
            <SkeletonComponent />
          ) : isError ? (
            <p
              style={{
                marginTop: "100px",
                color: "red",
                padding: "30px",
              }}
            >
              Une erreur s'est produite lors du chargement des données.
            </p>
          ) : datas && datas.length > 0 ? (
            datas.map((item, index) => (
              <Link
                key={index}
                to={`/detail-commande/${item?.id}`}
                style={{ textDecoration: "none" }}
              >
                <CommandeItemHome
                  client={item?._client?.nom_complet}
                  model={item?._model?.nom}
                  image={item?._model?.image}
                  date_commande={moment(item.date_commande).format(
                    "DD-MM-YYYY HH:mm"
                  )}
                />
              </Link>
            ))
          ) : (
            <p
              style={{
                marginTop: "100px",
                color: "red",
                padding: "30px",
              }}
            >
              {t("Pas de commandes disponibles")}
            </p>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Accueil;
